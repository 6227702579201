import styled from 'styled-components';

export const ProgressBar = styled.div`
    width: 100%;
    background-color: ${props => props.theme.gray};
    height: 8px;
    border-radius: 4px;
`;

export const Progress = styled.div`
    width: ${props => props.width}%;
    background-color: ${props => props.theme.blue};
    height: 8px;
    border-radius: 4px;
`;

export const Spend = styled.div`
    max-width: 100%;
    width: calc(${props => props.width}% + 20px);
    margin-right: 20px;
    height: 20px;
    text-align: right;
    font-weight: bold;
    margin-bottom: 20px;
`;

export const Wrapper = styled.div`
    color: ${props => (props.color == null ? props.theme.blue : props.color)};
`;

export const LabelWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Goal = styled.span`
    align-self: flex-end;
`;

export const Label = styled.span``;
