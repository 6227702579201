import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { GetFileUrl } from 'ibis-helpers/lib/FileHelper';
import DonateProgressBar from './DonateProgressBar';
import { Button } from './css/GlobalStyleComponents';
import { Image, Description, Title, Content } from './css/ProjectViewStyle';

class ProjectView extends Component {
    render() {
        return (
            <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    this.props.onClick(this.props.project.id);
                }}
            >
                <Image image={GetFileUrl(this.props.project.image)} />
                <Content>
                    <Title>{this.props.project.name}</Title>
                    <Description>{this.props.project.description}</Description>
                    <DonateProgressBar goal={this.props.project.compensationValueGoal} spend={this.props.project.compensationValueSpend} />
                    <Button visible>Donate</Button>
                </Content>
            </div>
        );
    }
}

ProjectView.defaultProps = {
    project: {},
};

ProjectView.propTypes = {
    onClick: PropTypes.func.isRequired,
    project: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        compensationValueGoal: PropTypes.number,
        compensationValueSpend: PropTypes.number,
        image: PropTypes.string,
    }),
};

export default ProjectView;
