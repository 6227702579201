import styled from 'styled-components';

export const DetailViewBox = styled.div`
    display: flex;
    height: 100%;
`;

export const ContentLeft = styled.div`
    overflow: hidden;
    display: flex;
    padding-top: 30px;
    width: 50%;
`;

export const ContentRight = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    background: url(${props => props.image}) center ${props => props.theme.gray};
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: space-between;
`;

export const DonationBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 80px;
`;

export const DonateButton = styled.div`
    background-color: ${props => props.theme.blue};
    color: ${props => props.theme.white};
    font-size: 18px;
`;

export const BackArrow = styled.div`
    padding-left: 20px;
    color: ${props => props.theme.black};
`;

export const CloseButton = styled.div`
    padding: 20px 20px 0px 0px;
    display: flex;
    justify-content: flex-end;
    color: ${props => props.theme.white};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
    user-select: text;
    overflow: hidden;
`;

export const Title = styled.h1`
    font-size: 28px;
    font-weight: 600;
    color: ${props => props.theme.black};
    margin: 0;
`;

export const Description = styled.p`
    color: ${props => props.theme.black};
    font-size: 16px;
`;
