import React, { Component } from 'react';

import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import Settings from '../../Settings';
import MyFlights from '../presentationComponents/MyFlights';

class MyFlightsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flights: null,
        };
    }

    componentDidMount() {
        this.getFlights();
    }

    async getFlights() {
        const year = new Date().getFullYear();
        HttpGet(`${Settings.apiUrls.carbon}/user/flights/${year}`)
            .then(response => this.setState({ flights: response }))
            .catch((error) => {
                switch (error.code) {
                case 408:
                    break;
                case 418:
                    break;
                default:
                    break;
                }
            });
    }

    render() {
        return <MyFlights flights={this.state.flights} />;
    }
}

export default MyFlightsContainer;
