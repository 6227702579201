import React, { Component, Suspense, lazy } from 'react';
import styled from 'styled-components';
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';

import ThemeProvider from 'ibis-design-system/lib/ThemeProvider';
import { UserContext } from 'ibis-design-system/lib/Providers/LoginProvider';
import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import NavigationProvider from 'ibis-design-system/lib/Providers/NavigationProvider';

import ControlPanel from './controlPanel/presentationComponents/ControlPanel';
import Settings from './Settings';
import Content from './mainSite/presentationComponents/ContentWrapper';

const Statistics = lazy(() => import('./Statistics/Main'));

const ApplicationBlock = styled.div`
    height: 100%;
    width: 100%;
    color: ${(props) => props.theme.text};
`;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            applications: [],
        };
    }

    ExcessToControlPanel(permissionRoleValue) {
        return [0, 1].includes(permissionRoleValue);
    }

    componentDidMount() {
        this.setApplications();
    }

    setApplications = () => {
        const applicationUrl = `${Settings.apiUrls.portal}/applications`;

        HttpGet(applicationUrl).then((response) => {
            this.setState({ applications: response });
        });
    };

    render() {
        return (
            <UserContext.Consumer>
                {(user) => (
                    <Router>
                        <ApplicationBlock>
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    component={() =>
                                        this.ExcessToControlPanel(
                                            user.permissions.permissionRoleValue
                                        ) ? (
                                            <Redirect to="/dashboard" />
                                        ) : (
                                            <ThemeProvider>
                                                <ControlPanel
                                                    title={Settings.title}
                                                    user={user.user}
                                                    applications={
                                                        this.state.applications
                                                    }
                                                />
                                            </ThemeProvider>
                                        )
                                    }
                                />
                                <Route
                                    path="/dashboard"
                                    render={() => (
                                        <Content
                                            user={user.user}
                                            title={Settings.applicationTitle}
                                            headerTitle={Settings.title}
                                            division={user.user.division}
                                            applications={
                                                this.state.applications
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    path="/stats"
                                    render={() => (
                                        <Suspense
                                            fallback={<div>Loading...</div>}
                                        >
                                            <Statistics />
                                        </Suspense>
                                    )}
                                />
                            </Switch>
                        </ApplicationBlock>
                    </Router>
                )}
            </UserContext.Consumer>
        );
    }
}

export default App;
