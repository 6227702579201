import styled from 'styled-components';

export const Icon = styled.div`
    padding: 5px;
    color: inherit;
    margin: auto;
`;

export const Value = styled.span`
    display: block;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: inherit;
`;

export const Label = styled.div`
    font-size: 14px !important;
    font-weight: normal;
    text-align: center;
    color: inherit;
    margin: auto;
`;
