import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';

import MessageBar from 'ibis-design-system/lib/components/core/MessageBar';
import Box from 'ibis-design-system/lib/components/core/Box';
import Text from 'ibis-design-system/lib/components/core/Text';
import Flex from 'ibis-design-system/lib/components/core/Flex';
import LoadScreen from 'ibis-design-system/lib/components/core/LoadScreen';
import Icon from 'ibis-design-system/lib/components/core/Icon';

import List from './List';

const CenterText = styled(Flex)`
    flex: 1;
    justify-content: center;
    flex-direction: column;
`;

function ListView(props) {
    const { items, message, selectedItems, itemsCount } = props;

    function renderList() {
        if (!items) return <LoadScreen />;
        if (items.length === 0)
            return (
                <CenterText>
                    <Text cannotSelect align="center">
                        No items found
                    </Text>
                </CenterText>
            );
        if (items.length > 0)
            return (
                <>
                    <Flex bg="bg" flex="0 0 40px">
                        <Box padding="10px 0px 0px 10px">
                            <Icon size="xs" name="Accept" />
                        </Box>
                        <Text variant="secondaryLabel" p="8px">
                            {selectedItems ? selectedItems.length : 0} selected of {itemsCount ? itemsCount : items ? items.length : 0}
                        </Text>
                    </Flex>
                    <Flex flex="1 1 auto">
                        <List
                            selectedItems={selectedItems}
                            items={items}
                            itemHeight={props.itemHeight}
                            onRenderListItem={props.onRenderListItem}
                            onSelectionChanged={props.onSelectionChanged}
                        />
                    </Flex>
                </>
            );
        return (
            <CenterText>
                <Text cannotSelect align="center" variant="warning">
                    List is not in a valid state
                </Text>
            </CenterText>
        );
    }

    return (
        <Flex flexDirection="column" flex={props.flex} style={{ overflow: 'auto' }}>
            {message ? (
                <Box flex="0 0 auto">
                    <MessageBar type={message.type} onDismiss={message.onDismiss}>
                        {message.text}
                    </MessageBar>
                </Box>
            ) : null}
            <Flex flex="1 1 auto" flexDirection="column">
                {renderList()}
            </Flex>
        </Flex>
    );
}

ListView.propTypes = {
    /** Indexes of the selected items */
    selected: Proptypes.array,
    /** Items inside the list */
    items: Proptypes.array,
    /** Total amount of items (if not supplied will take the length of items) */
    itemsCount: Proptypes.number,
    /** Information message for the user */
    message: Proptypes.shape({
        type: Proptypes.oneOf(['info', 'warning', 'success', 'error', 'blocked', 'severeWarning']),
        text: Proptypes.string,
        onDismiss: Proptypes.func,
    }),
};

ListView.defaultProps = {
    selected: [],
    items: null,
    message: null,
    itemsCount: null,
};

export default ListView;
