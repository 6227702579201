import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Slider } from 'office-ui-fabric-react/lib/Slider';

import { Donation, Wrapper } from './css/DonationSliderStyle';

class DonationSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        this.props.onChange(value);
        this.setState({ value: value });
    }

    calculateMax() {
        const cash = this.props.creditAvailiable - (this.props.creditAvailiable % this.props.step);
        if (cash < this.props.max) {
            return cash;
        }
        return this.props.max;
    }

    calculateWidth(value) {
        let width = (value / this.calculateMax()) * 100;
        if (isNaN(width)) width = 0;
        return width;
    }

    render() {
        if (this.props.creditAvailiable === 0) return false;
        return (
            <Wrapper>
                <Slider
                    style={{ color: '#FFFFFF' }}
                    value={this.state.value}
                    min={0}
                    max={this.calculateMax()}
                    step={this.props.step}
                    onChange={this.onChange}
                    showValue={false}
                />
                <Donation width={this.calculateWidth(this.state.value)}>
                    {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(this.state.value)}
                </Donation>
            </Wrapper>
        );
    }
}

DonationSlider.defaultProps = {
    step: 1,
};

DonationSlider.propTypes = {
    onChange: PropTypes.func.isRequired,
    creditAvailiable: PropTypes.number.isRequired,
    step: PropTypes.number,
    max: PropTypes.number.isRequired,
};

export default DonationSlider;
