import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import UserPicker from 'ibis-design-system/lib/components/pickers/UserPicker';

import Settings from '../../Settings';

class PassengersDetailView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedEmployees: null,
            passportName: null,
            key: 0,
            people: null,
            persons: null,
        };

        this.update = this.update.bind(this);
    }

    componentDidMount() {
        const state = this.state;
        let id = this.props.id;
        if (id === null) id = '';
        if (this.props.employee !== null) {
            state.selectedEmployees = [this.props.employee];
        }
        state.employeeId = id;
        state.passportName = this.props.name;
        state.key++;
        this.setState(state);
    }

    handleChange(value, target) {
        const state = this.state;
        state[target] = value;
        this.setState(state);
    }

    update(item) {
        if (item.length === 1) {
            this.props.update(this.state.passportName, item[0]);
        }
    }

    render() {
        return (
            <div style={{ width: '100%' }}>
                {this.props.message ? (
                    <MessageBar
                        messageBarType={this.props.message.type === 'succes' ? MessageBarType.success : MessageBarType.error}
                        isMultiline={false}
                        onDismiss={this.props.clearMessage}
                        dismissButtonAriaLabel="Close"
                    >
                        {this.props.message.text}
                    </MessageBar>
                ) : null}
                <div style={{ display: 'flex', flex: 1, padding: '30px' }}>
                    <div style={{ marginRight: '15px' }}>{this.state.passportName}</div>
                    <div style={{ flex: 1 }}>
                        <UserPicker
                            url={`${Settings.apiUrls.singleSignOn}/users/details/search?take=10&skip=0&term=`}
                            people={this.state.people}
                            onChanged={this.update}
                            key={this.state.key}
                            selected={this.state.selectedEmployees}
                            max={1}
                            disabled={false}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

PassengersDetailView.defaultProps = {
    name: null,
    id: null,
};

PassengersDetailView.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    update: PropTypes.func.isRequired,
};

export default PassengersDetailView;
