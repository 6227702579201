import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'ibis-helpers/lib/components/VisibilitySensor';

import { ProgressBar, Progress, Goal, Wrapper, LabelWrapper, Spend, Label } from './css/DonateProgressBarStyle';

class DonateProgressBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iteration: 0,
            spendWith: 0,
            spend: 0,
        };

        this.calculateFillWidth = this.calculateFillWidth.bind(this);
        this.VisibilityChanged = this.VisibilityChanged.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.spend !== this.props.spend) {
            this.calculateFillWidth();
        }
    }

    VisibilityChanged(isVisible) {
        if (isVisible === true && this.state.iteration === 0) this.calculateFillWidth();
    }

    calculateFillWidth() {
        // Calculate the spend for this iteration ensuring it will not be higher then the real spendage
        const spend = Math.min((this.props.goal / 100) * this.state.iteration, this.props.spend);
        // Calcualte the spend width, which is a percentage of iteration spend of the goal
        const spendWith = Math.round((spend / this.props.goal) * 100);
        this.setState({ spend: spend, iteration: this.state.iteration + 1, spendWith: spendWith });
        // Calculate percetnage that should be reached with the iteration
        const percentageGoal = Math.ceil((this.props.spend / this.props.goal) * 100);
        if (this.state.iteration <= percentageGoal) setTimeout(this.calculateFillWidth, 10);
    }

    render() {
        return (
            <div>
                <Wrapper color={this.props.labelColor}>
                    <LabelWrapper>
                        <Label>Goal:</Label>
                        <Goal>{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(this.props.goal)}</Goal>
                    </LabelWrapper>

                    <ProgressBar>
                        <Progress width={this.state.spendWith} />
                    </ProgressBar>

                    <Spend width={this.state.spendWith}>
                        {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(this.state.spend)}
                    </Spend>
                </Wrapper>
                <VisibilitySensor onVisibilityChanged={this.VisibilityChanged} />
            </div>
        );
    }
}

DonateProgressBar.defaultProps = {
    labelColor: null,
};

DonateProgressBar.propTypes = {
    goal: PropTypes.number.isRequired,
    spend: PropTypes.number.isRequired,
    labelColor: PropTypes.string,
};

export default DonateProgressBar;
