import React, { Component } from 'react';
import { ScrollablePane } from 'office-ui-fabric-react/lib-commonjs/ScrollablePane';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import MyFlightsContainer from '../containerComponents/MyFlightsContainer';
import ProjectsContainer from '../containerComponents/ProjectsContainer';
import StatsContainer from '../containerComponents/StatsContainer';
import Footer from './Footer';
import { Wrapper, Spacing } from './css/ContentStyle';
import OverviewContainer from '../containerComponents/OverviewContainer';

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            overviewKey: 0,
        };
    }

    render() {
        return (
            <ScrollablePane
                style={{
                    flex: 1,
                    position: 'relative',
                    backgroundColor: this.props.theme.appBackground,
                }}
            >
                <Wrapper>
                    <OverviewContainer
                        overviewKey={this.state.overviewKey}
                        title={this.props.title}
                        division={this.props.division}
                    />

                    <Spacing>
                        <MyFlightsContainer />
                    </Spacing>

                    <Spacing>
                        <ProjectsContainer
                            updateOverView={() =>
                                this.setState({
                                    overviewKey: this.state.overviewKey + 1,
                                })
                            }
                        />
                    </Spacing>

                    <Spacing>
                        <StatsContainer />
                    </Spacing>

                    <Spacing>
                        <Footer />
                    </Spacing>
                </Wrapper>
            </ScrollablePane>
        );
    }
}

Content.defaultProps = {
    division: '',
    user: {},
};

Content.propTypes = {
    division: PropTypes.string,
    user: PropTypes.object,
};

export default withTheme(Content);
