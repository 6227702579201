import React, { Component } from 'react';
import styled from 'styled-components';

const OuterBar = styled.div`
    display: flex;
    position: relative;
    height: 20px;
    width: 100%;
    background-color: ${props => props.theme.overviewBarsBackground};
`;

const InnerBar = styled.div`
    height: 100%;
    width: ${props => props.width}%;
`;

const Animation = styled.div`
    background-color: ${props => (props.error ? props.theme.error : props.theme.overviewBars)};
    height: 100%;
    width: 100%;
    animation: animate ${props => props.animationTime}ms;
    transition-timing-function: ease-in;
`;

const Value = styled.span`
    padding-left: 20px;
`;

class StatsProgressBar extends Component {
    render() {
        let width = Math.max((Math.log(this.props.current) / Math.log(this.props.max)) * 100 * 0.82, 1);
        const time = Math.round(width * 1500) / 100;
        if (isNaN(width)) {
            width = 0;
        }
        return (
            <OuterBar>
                <InnerBar width={width}>
                    <Animation className="animate" error={this.props.error} animationTime={time} />
                </InnerBar>
                <Value>{new Intl.NumberFormat('nl-NL').format(this.props.current)}t</Value>
            </OuterBar>
        );
    }
}

export default StatsProgressBar;
