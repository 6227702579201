import styled from 'styled-components';

export const MapWrapper = styled.div`
    height: 150px;
`;

export const ContentWrapper = styled.div`
    padding: 30px;
`;

export const MetaDataDonations = styled.div`
    height: 100%;
    flex: 0 0 450px;
    display: flex;
`;

export const DonationsLabel = styled.div`
    font-weight: 14px;
`;

export const DonationValue = styled.div`
    font-weight: 17px;
    text-align: right;
    margin-bottom: 10px;
`;

export const Description = styled.div``;

export const Sdgs = styled.div`
    margin-top: 30px;
`;

export const SdgImagesWrapper = styled.div`
    > * {
        margin: 0px 4px 4px 0px;
    }
`;
