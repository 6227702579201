import React from 'react';
import moment from 'moment';

import NumberInput from 'ibis-design-system/lib/components/inputFields/NumberInput';
import DatePicker from 'ibis-design-system/lib/components/inputFields/DatePicker';
import Flex from 'ibis-design-system/lib/components/core/Flex';
import Box from 'ibis-design-system/lib/components/core/Box';

function Finance(props) {
    const { id, patch, end, start } = props;

    function saveStartDate(field, value) {
        const patches = [];

        const dayDifference = moment(end).diff(moment(start), 'days');
        const newEndDate = moment(value)
            .add(dayDifference, 'day')
            .toISOString();

        patches.push({ op: 'replace', path: 'end', value: newEndDate });
        patches.push({ op: 'replace', path: field, value: value });
        patch(id, patches);
    }

    return (
        <>
            <Flex mb={3} flex="1" justifyContent="space-between">
                <Box flex="1">
                    <NumberInput
                        label="Donation goal"
                        prefix="EUR"
                        placeholder="Fill in the goal amount"
                        value={props.compensationValueGoal}
                        onChanged={value =>
                            patch(id, [
                                {
                                    op: 'replace',
                                    path: 'compensationValueGoal',
                                    value: value
                                }
                            ])
                        }
                    />
                </Box>
                <Box ml={3}>
                    <NumberInput
                        prefix="EUR"
                        label="Received donations"
                        placeholder="Donations received"
                        value={props.compensationValueSpend}
                        disabled
                    />
                </Box>
            </Flex>
            <Flex mb={3} flex="1" justifyContent="space-between">
                <Box flex="1">
                    <DatePicker
                        label="Start"
                        value={start}
                        placeholder="Select a date..."
                        onSelect={value => saveStartDate('start', value)}
                    />
                </Box>
                <Box flex="1" mb={3} ml={3}>
                    <DatePicker
                        label="End"
                        value={end}
                        placeholder="Select a end date..."
                        onSelect={value =>
                            patch(id, [
                                { op: 'replace', path: 'end', value: value }
                            ])
                        }
                    />
                </Box>
            </Flex>
        </>
    );
}

export default Finance;
