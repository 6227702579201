import styled from 'styled-components';

export const Donation = styled.div`
    color: white;
    font-weight: bold;
    width: calc(${props => props.width}% + 20px);
    max-width: 100%;
    text-align: right;
`;

export const Wrapper = styled.div`
    margin-bottom: 10px;
`;
