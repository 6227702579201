import React, { Component } from 'react';
import styled from 'styled-components';

import { Deltares, Recycle } from '../../Icons';

const Link = styled.a`
    color: #ffffff;
    margin-right: 45px;
    text-decoration: none;
    font-size: 15px;
`;

const DeltaresLogo = styled.div`
    width: 225px;
    height: 150px;
    color: ${props => props.theme.white};
    margin-right: 25px;
`;

const RecycleLogo = styled.div`
    width: 150px;
    height: 100px;
    color: ${props => props.theme.white};
    margin-top: 25px;
`;

const LinksBlock = styled.div`
    margin: 40px 0px;
`;

class Footer extends Component {
    render() {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <DeltaresLogo>{Deltares}</DeltaresLogo>
                    <RecycleLogo>{Recycle}</RecycleLogo>
                </div>

                <LinksBlock>
                    <Link href="#">Privacy statement</Link>
                    <Link href="#">Disclaimer</Link>
                    <Link href="mailto:CarbonAwareness@deltares.nl">Contact</Link>
                </LinksBlock>
            </div>
        );
    }
}

export default Footer;
