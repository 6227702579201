import styled from 'styled-components';

export const Button = styled.button`
    visibility: ${props => (props.visible ? 'visible' : 'hidden')};
    width: 100%;
    font-size: 18px;
    height: 44px;
    overflow: hidden;
    border-width: 0px;
    background-color: ${props => props.theme.blue};
    color: ${props => props.theme.white};
    font-weight: bold;
    cursor: pointer;
`;
