import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import Settings from '../../Settings';
import ProjectView from './../presentationComponents/ProjectView';
import ProjectDetailViewContainer from '../containerComponents/ProjectDetailViewContainer';

import { PanelHeader } from '../presentationComponents/css/PanelHeaderStyle';
import { ProjectViewWrapper } from '../presentationComponents/css/ProjectViewStyle';
import Dialog from '../presentationComponents/Dialog';


class ProjectsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: null,
            selectedProject: null,
        };

        this.selectProject = this.selectProject.bind(this);
    }

    componentDidMount() {
        // load the active projects
        this.getActiveProjects();
    }

    renderProjects(rows) {
        const width = 380;
        const margin = (this.props.width - (3 * width)) / 2;
        const rowComponents = [];
        rows.forEach((projects) => {
            const projectComponents = [];
            for (let i = 0; i < projects.length; i++) {
                const project = projects[i];
                if (i === 2) projectComponents.push(<ProjectViewWrapper key={project.id} margin={0} width={width}><ProjectView project={project} onClick={this.selectProject} /></ProjectViewWrapper>);
                else projectComponents.push(<ProjectViewWrapper key={project.id} margin={margin} width={width}><ProjectView key={project.id} project={project} onClick={this.selectProject} /></ProjectViewWrapper>);
            }
            rowComponents.push(<div key={rowComponents.length - 1} style={{ display: 'flex' }} >{projectComponents}</div>);
        });

        return rowComponents;
    }

    selectProject(id) {
        if (id == null) {
            this.props.updateOverView();
            this.getActiveProjects();
        }

        this.setState({ selectedProject: id });
    }

    renderProjectDetailView() {
        if (this.state.selectedProject == null) {
            return <div />;
        }
        return (
            <Dialog dismiss={() => this.selectProject(null)}>
                <ProjectDetailViewContainer id={this.state.selectedProject} dismiss={() => this.selectProject(null)} />
            </Dialog>
        );
    }

    splitProjectsIntoRows(projects) {
        const rows = [[]];
        for (let i = 0; i < projects.length; i++) {
            if (rows[rows.length - 1].length === 3) rows.push([]);
            rows[rows.length - 1].push(projects[i]);
        }
        return rows;
    }

    async getActiveProjects() {
        const self = this;
        HttpGet(`${Settings.apiUrls.carbon}/projects/active`)
            .then(response => self.setState({ projects: self.splitProjectsIntoRows(response) }))
            .catch((error) => {
                switch (error.code) {
                case 408:
                    break;
                case 418:
                    break;
                default:
                    break;
                }
            });
    }

    render() {
        if (this.state.projects === null) return false; // loading
        return (
            <div id="Projects">
                <PanelHeader>Support these projects</PanelHeader>
                {this.renderProjects(this.state.projects)}
                {this.renderProjectDetailView()}
            </div>
        );
    }
}

ProjectsContainer.defaultProps = {
    width: 1200,
};

ProjectsContainer.propTypes = {
    width: PropTypes.number,
    updateOverView: PropTypes.func.isRequired,
};

export default ProjectsContainer;
