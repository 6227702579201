import React, { Component } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';

import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import Settings from '../../Settings';
import Overview from '../presentationComponents/Overview';

class OverviewContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: null,
        };
    }

    componentDidMount() {
        this.getUserStats();
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.division !== this.props.division && this.props.division !== null) ||
            prevProps.overviewKey !== this.props.overviewKey
        ) {
            this.getUserStats();
        }
    }

    async getUserStats() {
        HttpGet(`${Settings.apiUrls.carbon}/user/stats/${this.props.division}/${Moment().year()}`)
            .then(response => this.setState({ info: response }))
            .catch((error) => {
                switch (error.code) {
                case 408:
                    break;
                case 418:
                    break;
                default:
                    break;
                }
            });
    }

    render() {
        return <Overview title={this.props.title} info={this.state.info} />;
    }
}

OverviewContainer.defaultProps = {
    overviewKey: 0,
};

OverviewContainer.propTypes = {
    division: PropTypes.string.isRequired,
    overviewKey: PropTypes.number,
};

export default OverviewContainer;
