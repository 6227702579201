import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Dialog from './Dialog';
import FlightDetailViewContainer from '../containerComponents/FlightDetailViewContainer';

const Value = styled.span`
    font-size: 16px;
    font-weight: ${props => (props.weight ? props.weight : 600)};
`;

const BigColumn = styled.div`
    padding: 3px 0px 8px 20px;
    flex: 4;
    text-align: left;
    cursor: pointer;
`;

const Column = styled.div`
    padding: 3px 0px 8px 0px;
    flex: 2;
    text-align: right;
    cursor: pointer;
`;

const TableContent = styled.div`
    display: flex;
    background-color: #eeeeee;
    margin: 2px 10px;
`;

class FlightTableRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogIsOpen: false,
        };

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }
    formatRoute(route) {
        return route.split('/').join(' - ');
    }

    closeDialog() {
        this.setState({ dialogIsOpen: false });
    }

    openDialog() {
        this.setState({ dialogIsOpen: true });
    }

    renderDialog() {
        if (this.state.dialogIsOpen) {
            return (
                <Dialog dismiss={this.closeDialog}>
                    <FlightDetailViewContainer dismiss={this.closeDialog} journeyId={this.props.id} />
                </Dialog>
            );
        }
        return <div />;
    }

    render() {
        return (
            <div>
                <TableContent onClick={this.openDialog} style={{}}>
                    <BigColumn>
                        <Value>{this.formatRoute(this.props.route)}</Value>
                        <br />
                        <Value weight="normal">{this.props.date}</Value>
                    </BigColumn>
                    <Column>
                        <Value>{new Intl.NumberFormat('nl-NL').format(this.props.distance)}</Value>
                        <br />
                    </Column>
                    <Column>
                        <Value>{new Intl.NumberFormat('nl-NL').format(this.props.footprint)}</Value>
                    </Column>
                    <Column>
                        <Value>{new Intl.NumberFormat('nl-NL').format(this.props.compensation)}</Value>
                    </Column>
                    <div style={{ flex: 3 }} />
                </TableContent>
                {this.renderDialog()}
            </div>
        );
    }
}

FlightTableRow.defaultProps = {
    route: '',
    distance: 0,
    footprint: 0,
    compensation: 0,
    date: null,
    id: null,
};

FlightTableRow.propTypes = {
    id: PropTypes.string,
    route: PropTypes.string,
    distance: PropTypes.number,
    footprint: PropTypes.number,
    compensation: PropTypes.number,
    date: PropTypes.string,
};

export default FlightTableRow;
