import React from 'react';

import Editview from 'ibis-design-system/lib/components/core/Editview';

import General from './EditFields/General';
import Finance from './EditFields/Finance';

function ProjectEditview(props) {
    const subjects = [
        {
            id: 'general',
            name: 'General',
            component: <General {...props} />,
        },
        {
            id: 'finance',
            name: 'Financial information',
            component: <Finance {...props} />,
        },
    ];

    return <Editview subjects={subjects} />;
}

export default ProjectEditview;
