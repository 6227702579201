import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FlightBlock, Title, NoFlightsText, Footer, LinkStyle } from './css/MyFlightsStyle';
import { Forest, PlaneAroundEarth, Train } from '../../Icons';
import FlightTable from './../presentationComponents/FlightTable';

class MyFlights extends Component {
    renderContent(renderTable) {
        if (renderTable === false) {
            return <NoFlightsText>No flights to compensate</NoFlightsText>;
        }
        return (
            <div>
                <FlightTable
                    journeys={this.props.flights.journeys}
                    distance={this.props.flights.distance}
                    emission={this.props.flights.emission}
                    compensation={this.props.flights.compensationValue}
                />

                <div style={{ display: 'flex', marginLeft: '475px' }}>
                    <div style={{ width: '140px', color: '#008BBF' }}>
                        <div style={{ margin: 'auto', width: '80%', height: '70px' }}>{PlaneAroundEarth}</div>
                        <div style={{ textAlign: 'center', marginLeft: 'auto' }}>
                            <span style={{ fontSize: '32px', fontWeight: 'bold' }}>
                                {new Intl.NumberFormat('nl-NL').format(this.props.flights.globeSpins)}x
                            </span>
                            <br />
                            <span style={{ fontSize: '14px', fontWeight: 400 }}>around the globe</span>
                        </div>
                    </div>
                    <div style={{ width: '140px', color: '#008BBF', marginLeft: '55px' }}>
                        <div style={{ margin: 'auto', width: '80%', height: '70px' }}>{Forest}</div>
                        <div style={{ textAlign: 'center', marginLeft: 'auto' }}>
                            <span style={{ fontSize: '32px', fontWeight: 'bold' }}>
                                ±{new Intl.NumberFormat('nl-NL').format(this.props.flights.trees)}
                            </span>
                            <br />
                            <span style={{ fontSize: '14px', fontWeight: 400 }}>trees for 40 years</span>
                        </div>
                    </div>
                    <div style={{ width: '140px', color: '#008BBF', marginLeft: '25px' }}>
                        <div style={{ margin: ' 10px auto', width: '80%', height: '50px' }}>{Train}</div>
                        <div style={{ textAlign: 'center', marginLeft: 'auto' }}>
                            <span style={{ fontSize: '32px', fontWeight: 'bold' }}>
                                {Intl.NumberFormat('nl-NL').format(this.props.flights.publicTransportationCost)}x
                            </span>
                            <br />
                            <span style={{ fontSize: '14px', fontWeight: 400 }}>Delft-Utrecht by train</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        if (this.props.flights === null) return false; // loading
        return (
            <FlightBlock id="MyFlightsTravel">
                <Title>My business flights</Title>

                {this.renderContent(this.props.flights.emission !== 0)}

                <Footer>
                    Data provided by Deltares travel agency. Flights/travels missing? Or incorrect data? Please send a note to{' '}
                    <LinkStyle href="mailto:CarbonAwareness@deltares.nl">CarbonAwareness@deltares.nl</LinkStyle>.
                </Footer>
            </FlightBlock>
        );
    }
}

MyFlights.defaultProps = {
    flights: null,
};

MyFlights.propTypes = {
    flights: PropTypes.shape({
        journeys: PropTypes.array,
        compensationValue: PropTypes.number,
        distance: PropTypes.number,
        emission: PropTypes.number,
        globeSpins: PropTypes.number,
        publicTransportationCost: PropTypes.number,
        trees: PropTypes.number,
        year: PropTypes.number,
    }),
};

export default MyFlights;
