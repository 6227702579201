import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: auto;
    width: 1200px;
    padding-top: 120px;
`;

export const Spacing = styled.div`
    margin-top: 50px;
`;

export const ContentBlock = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    svg path {
        fill: currentColor;
    }

    svg text {
        fill: currentColor;
    }

    svg {
        fill: currentColor;
        width: 100%;
        height: 100%;
        margin: auto;
        user-drag: none;
        user-select: none;
    }
`;
