import styled from 'styled-components';

export const DetailViewBox = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
`;

export const Header = styled.div`
    height: 80px;
    width: 100%;
    display: flex;
`;

export const BackArrow = styled.div`
    color: ${props => props.theme.black};
    height: 82px;
    padding-left: 23px;
    width: 78px;
    box-sizing: border-box;
    padding-top: 44px;
    line-height: 32px;
    font-size: 32px;
`;

export const Route = styled.div`
    height: 82px;
    box-sizing: border-box;
    padding-top: 44px;
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;
    flex: 1;
    color: ${props => props.theme.black};
`;

export const CloseButton = styled.div`
    color: ${props => props.theme.black};
    height: 82px;
    padding-left: 28px;
    padding-right: 14px;
    width: 78px;
    box-sizing: border-box;
    padding-top: 14px;
`;

export const SubHeader = styled.div`
    height: 38px;
    width: 100%;
    padding-left: 78px;
    color: ${props => props.theme.black};
    box-sizing: border-box;
    font-size: 18px;
`;

export const FlightMapBox = styled.div`
    height: 300px;
    width: 960px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    top: 120px;
`;

export const Label = styled.div`
    color: ${props => props.theme.black};
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    margin: auto;
    text-align: center;
    font-weight: 600;
    margin-bottom: 5px;
`;

export const Value = styled.div`
    color: ${props => props.theme.black};
    font-size: 25px;
    margin: auto;
    text-align: center;
    font-weight: 600;
    margin-bottom: 5px;
`;

export const ContentBlock = styled.div`
    color: ${props => props.theme.blue};
    display: flex;
    justify-content: center;
    padding-top: 30px;
`;

export const InfoBlock = styled.div`
    color: ${props => props.theme.blue};
    width: 200px;
    font-weight: 500;
    height: 168px;
`;
