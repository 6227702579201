import styled from 'styled-components';

export const Image = styled.div`
    width: 100%;
    height: 200px;
    background: ${props => (props.image == null ? props.theme.gray : 'url(' + props.image + ') no-repeat center center')};
    background-size: cover;
`;

export const ProjectViewWrapper = styled.div`
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    height: 518px;
    width: ${props => props.width}px;
    margin-bottom: ${props => props.margin}px;
    margin-right: ${props => props.margin}px;
    background-color: ${props => props.theme.white};
`;

export const Description = styled.p`
    font-size: 18px;
    height: 76px;
    margin-bottom: 22px;
    overflow: hidden;
`;

export const Title = styled.h2`
    font-size: 18px;
    height: 24px;
    overflow: hidden;
`;

export const Content = styled.div`
    padding: 8px 26px 26px 26px;
    overflow: hidden;
`;
