import React, { useEffect, useState } from 'react';

import { HttpGet, HttpPost, HttpPut } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import Settings from '../../Settings';
import FlightUploadView from '../presentationComponents/FlightUploadView';

function FlightUploadContainer(props) {
    const [importLogs, setImportLogs] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        HttpGet(`${Settings.apiUrls.carbon}/admin/travel-data/import-log?take=10`)
            .then(response => setImportLogs(response))
            .catch(error => setImportLogs([]));
    }, []);

    function killRunningProcess() {
        HttpPut(`${Settings.apiUrls.carbon}/admin/travel-data/start-import`, {}).then(() => {
            HttpGet(`${Settings.apiUrls.carbon}/admin/travel-data/import-log?take=10`)
                .then(response => setImportLogs(response))
                .catch(error => setImportLogs([]));
        });
    }

    function postTravelData(e) {
        setMessage(null);
        setUploading(true);
        e.preventDefault();
        const file = e.target[0].files[0];
        const data = new FormData();
        data.append('upload', file);

        HttpPost(`${Settings.apiUrls.carbon}/admin/travel-data`, data)
            .then(response => {
                setMessage({
                    type: 'success',
                    text: 'Uploaded travel data successfully',
                    dismiss: () => setMessage(null),
                });
            })
            .catch(error => {
                switch (error.code) {
                    case 400:
                        setMessage({
                            type: 'blocked',
                            text: 'Please upload an .xls format',
                            dismiss: () => setMessage(null),
                        });
                        break;
                    default:
                        setMessage({
                            type: 'severeWarning',
                            text: 'Unknow error occurred while uploading the travel data',
                            dismiss: () => setMessage(null),
                        });
                        break;
                }
            })
            .finally(() => setUploading(false));
    }

    if (!importLogs) return null;

    return (
        <FlightUploadView reset={killRunningProcess} logs={importLogs} postFile={postTravelData} uploading={uploading} message={message} />
    );
}

export default FlightUploadContainer;
