import React, { Component } from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Polyline, Marker } from 'react-google-maps';
import PropTypes from 'prop-types';

class FlightMap extends Component {
    constructor(props) {
        super(props);
        this.onMapMounted = this.onMapMounted.bind(this);
        this.renderMarkers = this.renderMarkers.bind(this);
        this.greatCircleDistance = this.greatCircleDistance.bind(this);
        this.interpolateRoute = this.interpolateRoute.bind(this);
    }

    onMapMounted(map) {
        if (map === null || this.props.flights.length === 0) return;

        // Interpolate the route to find the points on the route
        // Currently the Google Maps Api (v3) is not correctly fitting the box arround the polyline with
        // great circle distance. Therefore the segments of the route are extracted, per segment there are points
        // interpolated on the great circle and from all those points a bounding box is determined
        let points = [];

        for (let i = 1; i < this.props.flights.length; i++) {
            const p1 = this.props.flights[i - 1];
            const p2 = this.props.flights[i];
            if (p1.lat !== p2.lat && p1.lng !== p2.lng) {
                const route = this.interpolateRoute(p1, p2);
                points = points.concat(route);
            }
        }

        // Find the box of the route
        const box = this.getBounds(points);

        // And set the bounds
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng(box.c1.lat, box.c1.lng));
        bounds.extend(new window.google.maps.LatLng(box.c2.lat, box.c2.lng));
        map.fitBounds(bounds);
    }

    /**
     * This method calculates the create circle distance (in meters) of the route
     * @param {{lat,lng}} p1 The origin of the route
     * @param {{lat,lng}} p2 The destination of the route
     */
    greatCircleDistance(p1, p2) {
        const lat1 = (p1.lat * Math.PI) / 180.0;
        const lat2 = (p2.lat * Math.PI) / 180.0;

        const lng1 = (p1.lng * Math.PI) / 180.0;
        const lng2 = (p2.lng * Math.PI) / 180.0;

        const sinLat = Math.sin((lat1 - lat2) / 2);
        const sinLng = Math.sin((lng1 - lng2) / 2);
        const s = Math.sqrt(sinLat ** 2 + Math.cos(lat1) * Math.cos(lat2) * sinLng ** 2);
        const d = 2 * Math.asin(s);
        return d * 6378137.0;
    }

    /**
     * This method interpolates a route between two points
     * @param {{lat,lng}} p1 The origin of the route
     * @param {{lat,lng}} p2 The destination of the route
     */
    interpolateRoute(p1, p2) {
        const points = [];
        const delta = 0.05;
        const d = this.greatCircleDistance(p1, p2) / 6378137.0;

        const lat1 = (p1.lat * Math.PI) / 180.0;
        const lat2 = (p2.lat * Math.PI) / 180.0;

        const lng1 = (p1.lng * Math.PI) / 180.0;
        const lng2 = (p2.lng * Math.PI) / 180.0;

        // see http://www.edwilliams.org/avform.htm#Intermediate
        for (let f = delta; f < 1; f += delta) {
            const a = Math.sin((1 - f) * d) / Math.sin(d);
            const b = Math.sin(f * d) / Math.sin(d);
            const x = a * Math.cos(lat1) * Math.cos(lng1) + b * Math.cos(lat2) * Math.cos(lng2);
            const y = a * Math.cos(lat1) * Math.sin(lng1) + b * Math.cos(lat2) * Math.sin(lng2);
            const z = a * Math.sin(lat1) + b * Math.sin(lat2);
            const lat = (Math.atan2(z, Math.sqrt(x ** 2 + y ** 2)) * 180) / Math.PI;
            const lng = (Math.atan2(y, x) * 180) / Math.PI;

            points.push({ lat: lat, lng: lng });
        }

        return points;
    }

    getBounds(points) {
        if (points.length === 0) return {};

        let maxLat = points[0].lat;
        let minLat = points[0].lat;
        let maxLng = points[0].lng;
        let minLng = points[0].lng;

        points.forEach((point) => {
            maxLat = point.lat > maxLat ? point.lat : maxLat;
            minLat = point.lat < minLat ? point.lat : minLat;
            maxLng = point.lng > maxLng ? point.lng : maxLng;
            minLng = point.lng < minLng ? point.lng : minLng;
        });

        // The thing is.. the bound will be on the hard edge now..
        maxLat = maxLat < 0 ? maxLat - 0.5 : maxLat + 0.5;
        minLat = minLat < 0 ? minLat + 0.5 : minLat - 0.5;
        maxLng = maxLng < 0 ? maxLng - 0.5 : maxLng + 0.5;
        minLng = minLng < 0 ? minLng + 0.5 : minLng - 0.5;

        return { c1: { lat: maxLat, lng: minLng }, c2: { lat: minLat, lng: maxLng } };
    }

    renderMarkers() {
        const markers = [];

        // define the style of the markers
        const circle = 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0';
        const icon = {
            path: circle,
            scale: 0.35,
            fillColor: '#008BBF',
            fillOpacity: 1,
            strokeWeight: 0,
        };

        const flights = this.props.flights;
        for (let i = 0; i < flights.length; i++) {
            const flight = flights[i];
            markers.push(<Marker key={i} icon={icon} position={flight} />);
        }

        return markers;
    }

    render() {
        // Define the style of the map
        const mapStyles = [
            {
                featureType: 'landscape',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'transit',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'water',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'road',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
            },
            {
                stylers: [{ hue: '#00aaff' }, { saturation: -100 }, { gamma: 2.15 }, { lightness: 12 }],
            },
            {
                featureType: 'road',
                elementType: 'labels.text.fill',
                stylers: [{ visibility: 'on' }, { lightness: 24 }],
            },
            {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{ lightness: 57 }],
            },
        ];

        const controlOptions = {
            streetViewControl: false,
            scaleControl: false,
            mapTypeControl: false,
            panControl: false,
            zoomControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: mapStyles,
        };

        const flightOptions = {
            path: this.props.flights,
            strokeColor: '#008BBF',
            strokeOpacity: 1,
            strokeWeight: 3.5,
            icons: [
                {
                    offset: '0',
                    repeat: '20px',
                },
            ],
            geodesic: true,
        };

        const MapHolder = (
            <GoogleMap
                disableDefaultUI
                ref={this.onMapMounted}
                defaultOptions={controlOptions}
                defaultZoom={1.3}
                defaultCenter={{ lat: 0, lng: 0 }}
            >
                <Polyline geodesic={true} options={flightOptions} />
                {this.renderMarkers()}
            </GoogleMap>
        );

        const Map = withScriptjs(withGoogleMap(() => MapHolder));

        return (
            <Map
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAuyg8dPfCuTF0KWRQc2zJp9nPVQu_yo8E"
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '100%' }} />}
                mapElement={<div style={{ height: '100%' }} />}
            />
        );
    }
}

FlightMap.defaultProps = {
    flights: [],
};

FlightMap.propTypes = {
    flights: PropTypes.arrayOf(PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }).isRequired),
};

export default FlightMap;
