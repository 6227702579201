import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import DateCards from 'ibis-design-system/lib/components/patterns/DateCards/DateCards';
import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import SDGs from 'ibis-design-system/lib/components/inputFields/SDGs/SDGs';

import Flex from 'ibis-design-system/lib/components/core/Flex';
import Box from 'ibis-design-system/lib/components/core/Box';
import Text from 'ibis-design-system/lib/components/core/Text';

import Settings from '../../../Settings';
import {
    DonationsLabel,
    DonationValue,
    MetaDataDonations
} from '../css/ProjectOverviewStyle';

function formatCurrency(currency) {
    return new Intl.NumberFormat('nl-NL').format(currency);
}

function ProjectOverview(props) {
    const {
        compensationValueGoal,
        compensationValueSpend,
        start,
        end,
        description
    } = props;
    const [percentageDonated, setPercentageDonated] = useState(0);
    const [daysLeft, setDaysLeft] = useState(0);
    const [dayDifference, setDayDifference] = useState(100);
    const [sdgs, setSDGs] = useState([]);

    // cancel return needs to be implemented
    useEffect(() => {
        HttpGet(`${Settings.apiUrls.globalConfig}/data/sdgs`)
            .then(response => {
                setSDGs(response);
            })
            .catch(error => {
                switch (error.code) {
                    case 408:
                        break;
                    case 418:
                        break;
                    default:
                        break;
                }
            });
    }, []);

    useEffect(() => {
        let donatePercentage =
            (compensationValueSpend / compensationValueGoal) * 100;
        donatePercentage = Math.round(donatePercentage * 100) / 100;
        if (compensationValueGoal === 0) {
            donatePercentage = 100;
        }
        setPercentageDonated(donatePercentage);
    }, [compensationValueGoal, compensationValueSpend]);

    useEffect(() => {
        const now = moment().startOf('day');
        const startDate = moment(start).startOf('day');
        let daysLeft = 0;
        let dayDifference = 100;
        if (end) {
            const endDate = moment(end).startOf('day');
            daysLeft = endDate.diff(now, 'days') + 1;
            daysLeft = daysLeft < 0 ? 0 : daysLeft;
            dayDifference = Math.abs(startDate.diff(endDate, 'days')) + 1;
        }
        setDaysLeft(daysLeft);
        setDayDifference(dayDifference);
    }, [start, end]);

    return (
        <Flex flexDirection="column" width="100%">
            {/* <Map apiKey={Settings.mapsKey} flex="0 0 150px" options={{ gestureHandling: 'none' }} /> */}
            <Flex bg="white" p={3} mt="2px" flex="0 0 auto">
                <MetaDataDonations>
                    <div style={{ width: '160px' }}>
                        <DonationsLabel>Donation goal</DonationsLabel>
                        <DonationValue>
                            EUR {formatCurrency(compensationValueGoal)}
                        </DonationValue>
                        <DonationsLabel>Received donations</DonationsLabel>
                        <DonationValue>
                            EUR {formatCurrency(compensationValueSpend)}
                        </DonationValue>
                    </div>
                    <div
                        style={{
                            width: '95px',
                            height: '95px',
                            marginLeft: '20px'
                        }}
                    >
                        <CircularProgressbar
                            value={percentageDonated}
                            text={`${percentageDonated}%`}
                        />
                    </div>
                </MetaDataDonations>
                <Flex flex="1 1 560px">
                    <DateCards dateStart={start} dateEnd={end} />
                    <div
                        style={{
                            width: '95px',
                            height: '95px',
                            marginLeft: '20px'
                        }}
                    >
                        <CircularProgressbarWithChildren
                            value={(daysLeft / dayDifference) * 100}
                        >
                            <div style={{ fontWeight: 700, fontSize: '24px' }}>
                                {daysLeft}
                            </div>
                            <div>{daysLeft === 1 ? 'day' : 'days'} left</div>
                        </CircularProgressbarWithChildren>
                    </div>
                </Flex>
            </Flex>
            <Flex p={3}>
                <Box>
                    <Box mb={3}>
                        <Text>{description}</Text>
                    </Box>
                    <SDGs
                        availableSDGs={sdgs}
                        pickedSDGs={props.goals}
                        fileServiceUrl={Settings.apiUrls.fileService}
                    />
                </Box>
            </Flex>
        </Flex>
    );
}

export default ProjectOverview;
