import React, { Component } from 'react';
import styled from 'styled-components';
import Moment from 'moment';

import { Persona, PersonaSize } from 'office-ui-fabric-react/lib-commonjs/Persona';
import { DetailsList } from 'office-ui-fabric-react/lib-commonjs/DetailsList';

const Wrapper = styled.div`
    width: 100%;
`;

const columns = [
    {
        key: 'name',
        minWidth: 150,
        name: 'Employee',
        isResizable: true,
        isSorted: false,
    },
    {
        key: 'spendureDate',
        minWidth: 100,
        name: 'Date of Donation',
        isResizable: true,
        isSorted: false,
    },
    {
        key: 'value',
        minWidth: 100,
        name: 'Donation Amount',
        isResizable: true,
        isSorted: false,
    },
];

class DonationsDetailView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderItemColumn(item, index, column) {
        const fieldContent = item[column.key || ''];
        switch (column.key) {
        case 'name':
            return <Persona imageUrl={item.photo} size={PersonaSize.size24} text={fieldContent} />;
        case 'spendureDate':
            return <span>{Moment(fieldContent).format('DD MMM, YYYY')}</span>;
        case 'value':
            return (
                <div style={{ textAlign: 'right' }}>
                    {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(fieldContent)}
                </div>
            );
        default:
            return <span>{fieldContent}</span>;
        }
    }

    render() {
        return (
            <Wrapper>
                <DetailsList
                    style={{ width: '100%' }}
                    items={this.props.donations}
                    onRenderItemColumn={this.renderItemColumn}
                    columns={columns}
                />
            </Wrapper>
        );
    }
}

export default DonationsDetailView;
