import React, { Component } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FlightTableRow from './FlightTableRow';

const TotalLabel = styled.label`
    flex: 2;
    font-weight: 600;
    font-size: 18px;
    color: #008bbf;
`;

const Header = styled.h3`
    font-size: 18px;
    font-weight: 600;
    flex: 2;
    margin: 0;
`;

const HeaderBigColumn = styled.h3`
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    flex: 4;
    margin: 0;
`;

class FlightTable extends Component {
    renderFlights() {
        const flights = this.props.journeys;
        if (flights == null) {
            return <div />;
        }
        const flightComponents = [];
        for (let i = 0; i < flights.length; i++) {
            const flight = flights[i];
            flightComponents.push(<FlightTableRow
                key={i}
                id={flight.id}
                route={flight.route}
                distance={flight.distance}
                footprint={flight.emission}
                compensation={flight.compensationValue}
                date={Moment(flight.departureDate).format('DD-MM-YYYY')}
            />);
        }
        return flightComponents;
    }

    render() {
        return (
            <div>
                <div style={{ textAlign: 'right', display: 'flex', padding: '0px 0px 30px 30px' }}>
                    <HeaderBigColumn>Route</HeaderBigColumn>
                    <Header>Distance (km)</Header>
                    <Header>Footprint (t CO₂)</Header>
                    <Header>Compensation (EUR)</Header>
                    <span style={{ flex: 3 }} />
                </div>
                <div>{this.renderFlights()}</div>
                <div style={{ textAlign: 'right', display: 'flex', paddingLeft: '30px' }}>
                    <div style={{ flex: 4 }} />
                    <TotalLabel>{new Intl.NumberFormat('nl-NL').format(this.props.distance)}</TotalLabel>
                    <TotalLabel>{new Intl.NumberFormat('nl-NL').format(this.props.emission)}</TotalLabel>
                    <TotalLabel>{new Intl.NumberFormat('nl-NL').format(this.props.compensation)}</TotalLabel>
                    <div style={{ flex: 3 }} />
                </div>
            </div>
        );
    }
}

FlightTable.defaultProps = {
    journeys: [],
    distance: 0,
    emission: 0,
    compensation: 0,
};

FlightTable.propTypes = {
    journeys: PropTypes.arrayOf(PropTypes.object),
    distance: PropTypes.number,
    emission: PropTypes.number,
    compensation: PropTypes.number,
};

export default FlightTable;
