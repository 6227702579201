import React, { Component } from 'react';
import PropTypes from 'prop-types';

import VisibilitySensor from 'ibis-helpers/lib/components/VisibilitySensor';

import StatsProgressBar from './/StatsProgressBar';
import { Globe } from '../../Icons';
import {
    CurrentValue,
    Label,
    BigLabel,
    ProgressBarBox,
    BarLabel,
    FootNote,
    StatsHeader,
    StatsBlock,
    OverviewBlock,
    LogoBlock,
    Title,
    Logo,
    CompensateBlock,
    Paragraph,
    MyFootPrintBlock,
    StickyHeader,
    StickyFootPrintBlock,
    StickyTitleBlock,
    StickyTitle,
    StickyLogo,
    CurrentBlock,
    EmissionValue,
    CreditValue,
    MyFootPrintLeft,
    MyFootPrintRight,
    CreditLabel,
    CurrentWrapper,
    SmallLabel,
    SmallEmissionValue,
    CurrentWrapperHeader,
} from './css/OverviewStyle';

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stickyVisible: false,
        };
    }

    renderCompenstateText(type) {
        switch (type) {
            case 'None':
                return 'In our administration there are no business flights registered on your account.';
            case 'Regular':
                return 'Your CO₂ emission due to flying must be compensated by donating to the selected projects of your choice.';
            case 'Top':
                return 'Your high CO₂ emissions due to frequent flying must be compensated by donating to the selected projects of your choice.';
            default:
                return 'Sorry we could not retrieve your emission at this time';
        }
    }

    renderCreditBlock(credit, emission, type) {
        switch (type) {
            case 'None':
                return (
                    <CurrentWrapper column>
                        <BigLabel>Thank you!</BigLabel>
                        <Label>No flights to compensate.</Label>
                    </CurrentWrapper>
                );
            case 'Regular':
            case 'Top':
                return (
                    <CurrentWrapper>
                        <Label>To compensate</Label>
                        <CurrentValue>
                            <EmissionValue>{emission}t</EmissionValue>
                            <CreditValue>
                                {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(credit)}
                            </CreditValue>
                        </CurrentValue>
                    </CurrentWrapper>
                );
            default:
                return false;
        }
    }

    renderHeaderCreditBlock(credit, emission, type) {
        switch (type) {
            case 'None':
                return false;
            case 'Regular':
            case 'Top':
                return (
                    <CurrentWrapperHeader>
                        <SmallLabel>To compensate</SmallLabel>
                        <CurrentValue>
                            <SmallEmissionValue>{emission}t</SmallEmissionValue>
                            <CreditValue>
                                <SmallLabel>
                                    {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(credit)}
                                </SmallLabel>
                            </CreditValue>
                        </CurrentValue>
                    </CurrentWrapperHeader>
                );
            default:
                return false;
        }
    }

    renderSticky() {
        return (
            <StickyHeader id="StickHeader" active={this.state.stickyVisible}>
                <div style={{ flex: 3.5 }} />
                <StickyTitleBlock>
                    <StickyTitle>{this.props.title}</StickyTitle>
                    <div style={{ flex: 1 }}>
                        <StickyLogo>{Globe}</StickyLogo>
                    </div>
                </StickyTitleBlock>
                <StickyFootPrintBlock>
                    {this.renderHeaderCreditBlock(
                        this.props.info.credit,
                        this.props.info.carbonStillToCompensate,
                        this.props.info.flyerType,
                    )}
                </StickyFootPrintBlock>
            </StickyHeader>
        );
    }

    renderFootPrintBlock(type, credit, emission) {
        switch (type) {
            case 'None':
            case 'Regular':
                return (
                    <MyFootPrintBlock>
                        <MyFootPrintLeft>
                            <Label>My total CO₂ flight-footprint</Label>
                            <CreditLabel>Equals to</CreditLabel>
                        </MyFootPrintLeft>
                        <MyFootPrintRight>
                            <BigLabel>{emission}t</BigLabel>
                            <CreditValue>
                                {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(credit)}
                            </CreditValue>
                        </MyFootPrintRight>
                    </MyFootPrintBlock>
                );
            case 'Top':
                return (
                    <MyFootPrintBlock frequentFlyer>
                        <MyFootPrintLeft>
                            <Label>My total CO₂ flight-footprint</Label>
                            <CreditLabel>Equals to</CreditLabel>
                        </MyFootPrintLeft>
                        <MyFootPrintRight>
                            <BigLabel>{emission}t</BigLabel>
                            <CreditValue>
                                {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(credit)}
                            </CreditValue>
                        </MyFootPrintRight>
                    </MyFootPrintBlock>
                );
            default:
                return false;
        }
    }

    render() {
        if (this.props.info === null) return false; // loading
        return (
            <OverviewBlock id="Overview">
                {this.renderSticky()}
                <StatsBlock>
                    <StatsHeader>
                        Deltares aims to reduce our CO₂ flight-footprint. If a flight is required, let&apos;s compensate!
                    </StatsHeader>
                    <ProgressBarBox>
                        <BarLabel>Maximum amount of CO₂ per person per year to halt climate change</BarLabel>
                        <StatsProgressBar current={this.props.info.goalEmission} max={this.props.info.totalEmission} />
                    </ProgressBarBox>
                    <ProgressBarBox>
                        <BarLabel>Total amount of CO₂ of all my flights*</BarLabel>
                        <StatsProgressBar current={this.props.info.personalEmission} max={this.props.info.totalEmission} />
                    </ProgressBarBox>
                    <ProgressBarBox>
                        <BarLabel>Total amount of CO₂ of all flights by {this.props.info.division}*</BarLabel>
                        <StatsProgressBar current={this.props.info.divisionEmission} max={this.props.info.totalEmission} />
                    </ProgressBarBox>
                    <ProgressBarBox>
                        <BarLabel>Total amount of CO₂ of all flights by Deltares employees*</BarLabel>
                        <StatsProgressBar current={this.props.info.totalEmission} max={this.props.info.totalEmission} />
                    </ProgressBarBox>
                    <FootNote>* Affected period from january {this.props.info.year}. Only registerd flights by Deltares travel agency.</FootNote>
                </StatsBlock>

                <LogoBlock>
                    <Title>{this.props.title}</Title>
                    <Logo>{Globe}</Logo>
                </LogoBlock>

                <CompensateBlock>
                    <Paragraph>
                        {this.renderCompenstateText(this.props.info.flyerType)}
                    </Paragraph>
                    {this.renderFootPrintBlock(this.props.info.flyerType, this.props.info.personalEmissionValue, this.props.info.personalEmission)}
                    <CurrentBlock>
                        {this.renderCreditBlock(this.props.info.credit, this.props.info.carbonStillToCompensate, this.props.info.flyerType)}
                    </CurrentBlock>
                    <VisibilitySensor onVisibilityChanged={isVisible => this.setState({ stickyVisible: !isVisible })} />
                </CompensateBlock>
            </OverviewBlock>
        );
    }
}

Overview.defaultProps = {
    title: '',
    info: null,
};

Overview.propTypes = {
    title: PropTypes.string,
    info: PropTypes.shape({
        flyerType: PropTypes.string,
        credit: PropTypes.number,
        division: PropTypes.string,
        personalEmission: PropTypes.number,
        goalEmission: PropTypes.number,
        totalEmission: PropTypes.number,
        divisionEmission: PropTypes.number,
    }),
};

export default Overview;
