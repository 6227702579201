import styled from 'styled-components';

export const Active = {
    opacity: 1,
    textDecoration: 'inherit',
    cursor: 'default',
};

export const InActive = {
    opacity: 0.333,
    textDecoration: 'underline',
    cursor: 'pointer',
};

export const PanelHeader = styled.h2`
    font-size: 32px;
    font-weight: normal;
    padding-left: 20px;
    color: ${props => props.theme.white};
`;

export const PanelHeaderElement = styled.span`
    opacity: ${props => props.theme.opacity};
    text-decoration: ${props => props.theme.textDecoration};
    user-select: none;
    cursor: ${props => props.theme.cursor};
`;
