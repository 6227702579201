import React from 'react';

import Header from 'ibis-design-system/lib/components/core/Header/Header';
import { useUserState } from 'ibis-design-system/lib/Providers/LoginProvider';

import Settings from '../../Settings';

function HeaderContainer(props) {
    const user = useUserState();
    return (
        <Header
            user={user}
            singleSignOutUrl={Settings.uiUrls.singleSignOn + '/signout'}
            applicationName={props.headerTitle}
            applications={props.applications}
            portalUrl={Settings.uiUrls.portal}
            onToolsButtonClick={props.setPanelContent}
            notificationsApiUrl={Settings.apiUrls.portal}
        />
    );
}

export default HeaderContainer;
