import React from 'react';
import moment from 'moment';

import Flex from 'ibis-design-system/lib/components/core/Flex';
import Box from 'ibis-design-system/lib/components/core/Box';
import Icon from 'ibis-design-system/lib/components/core/Icon';

function LogRow({ id, start, errors, inProgress, isCompleted, processed, end }) {
    return (
        <Flex>
            <Box mr={5}>
                <Icon name={isCompleted ? 'Accept' : 'ChromeClose'} />
            </Box>
            <Box>{moment(start).format('dd DD MMM, YYYY')}</Box>
        </Flex>
    );
}

export default LogRow;
