import React from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as ReactWindowList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

function List(props) {
    const { items, selectedItems, itemHeight } = props;

    const Row = ({ index, style }) => (
        <div onClick={() => props.onSelectionChanged([index])} style={style}>
            {props.onRenderListItem(
                items[index],
                index,
                selectedItems.includes(index)
            )}
        </div>
    );

    if (!items) return <>Loading...</>;

    return (
        <AutoSizer>
            {({ height, width }) => (
                <ReactWindowList
                    height={height}
                    itemCount={items.length}
                    itemSize={itemHeight}
                    width={width}
                >
                    {Row}
                </ReactWindowList>
            )}
        </AutoSizer>
    );
}

List.defaultProps = {
    items: null,
    selectedItems: [],
    itemHeight: 82
};

List.propTypes = {
    /** Items in the list */
    items: PropTypes.array,
    /** Indexes of the selected items */
    selectedItems: PropTypes.array,
    /** Height of a single item. */
    itemHeight: PropTypes.number.isRequired
};

export default List;
