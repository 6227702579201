import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'office-ui-fabric-react/lib-commonjs/Button';
import { withTheme } from 'styled-components';

import { GetFileUrl } from 'ibis-helpers/lib/FileHelper';

import DonateProgressBar from './DonateProgressBar';
import DonationSlider from './DonationSlider';
import {
    DetailViewBox,
    ContentLeft,
    ContentRight,
    BackArrow,
    CloseButton,
    Content,
    Title,
    Description,
    DonationBox,
} from './css/ProjectDetailViewStyle';
import { Button } from './css/GlobalStyleComponents';

class ProjectDetailView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            donationKey: 0,
        };
    }

    render() {
        if (this.props.project.id == null) {
            return <div />;
        }
        return (
            <DetailViewBox>
                <ContentLeft>
                    <BackArrow>
                        <IconButton
                            style={{ color: 'inherit' }}
                            iconProps={{ iconName: 'ChevronLeftSmall' }}
                            onClick={this.props.onDismiss}
                        />
                    </BackArrow>
                    <Content>
                        <Title>{this.props.project.name}</Title>
                        <Description>{this.props.project.description}</Description>
                    </Content>
                </ContentLeft>

                <ContentRight image={GetFileUrl(this.props.project.image)}>
                    <CloseButton>
                        <IconButton style={{ color: 'inherit' }} iconProps={{ iconName: 'ChromeClose' }} onClick={this.props.onDismiss} />
                    </CloseButton>
                    <DonationBox>
                        <DonateProgressBar
                            labelColor={this.props.theme.white}
                            goal={this.props.project.compensationValueGoal}
                            spend={this.props.project.compensationValueSpend}
                        />
                        <DonationSlider
                            key={this.state.donationKey}
                            creditAvailiable={this.props.credit}
                            max={this.props.project.compensationValueGoal - this.props.project.compensationValueSpend}
                            onChange={value => this.setState({ value: value })}
                        />
                        <Button
                            visible={this.props.credit > 0}
                            onClick={() => {
                                this.setState({ donationKey: this.state.donationKey + 1 });
                                this.props.onDonate(this.state.value);
                            }}
                        >
                            Donate
                        </Button>
                    </DonationBox>
                </ContentRight>
            </DetailViewBox>
        );
    }
}

ProjectDetailView.defaultProps = {
    project: {},
    credit: 0,
};

ProjectDetailView.propTypes = {
    project: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
        compensationValueGoal: PropTypes.number,
        compensationValueSpend: PropTypes.number,
    }),
    credit: PropTypes.number,
    onDismiss: PropTypes.func.isRequired,
    onDonate: PropTypes.func.isRequired,
};

export default withTheme(ProjectDetailView);
