import { SetFileServiceUrls } from 'ibis-helpers/lib/Config';

let Settings = {};

export function SetSettings(json) {
    Settings = json;
    SetFileServiceUrls(
        Settings.uiUrls.fileService,
        Settings.apiUrls.fileService
    );
}

export { Settings as default };
