import React, { Component } from 'react';
import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import Settings from '../../Settings';
import StatsView from '../presentationComponents/StatsView';

class StatsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: null,
        };
        this.processStatsData = this.processStatsData.bind(this);
        this.getDeltaresStats = this.getDeltaresStats.bind(this);
    }
    componentDidMount() {
        // determine the year
        const year = new Date().getFullYear();
        // ensure to load the deltares wide statitics
        this.getDeltaresStats(year);
    }

    /**
     * This method will process the stats data and save them in an array
     * @param {*} stats The stats as provided by the api
     * @param {string} year A unique key for the data state (like 0 or the year)
     */
    processStatsData(stats, year) {
        const data = {
            distance: { value: stats.distance, altValue: stats.globeSpins },
            emission: { value: stats.emission, altValue: stats.trees },
            compensation: { value: stats.compensationValue, altValue: stats.publicTransportationCost },
            flights: { value: stats.flightCount, altValue: stats.flightPerDay },
            employee: { value: stats.employeeCount, altValue: stats.averageFlightPerEmployee },
            compensated: { value: stats.flightsCompensated, altValue: stats.averageFlightsCompensated },
            year: year,
        };
        this.setState({ stats: data });
    }

    /**
     * Get the statistics for deltares given the year.
     * @param {*} year
     */
    async getDeltaresStats(year) {
        await HttpGet(`${Settings.apiUrls.carbon}/stats/year/${year}`)
            .then(response => this.processStatsData(response, year))
            .catch((error) => {
                switch (error.code) {
                case 408:
                    break;
                case 418:
                    break;
                default:
                    break;
                }
            });
    }

    render() {
        if (this.state.stats === null) return false; // loading
        return <StatsView onYearChange={this.getDeltaresStats} stats={this.state.stats} />;
    }
}

export default StatsContainer;
