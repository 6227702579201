import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import Settings from '../../Settings';
import FlightDetailView from './../presentationComponents/FlightDetailView';

class FlightDetailViewContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            journey: {
                compensationValue: NaN,
                distance: NaN,
                emission: NaN,
                flights: [],
                globeSpins: NaN,
                publicTransportationCost: NaN,
                route: 'no route',
                trees: NaN,
            },
        };
        this.getFlights = this.getFlights.bind(this);
    }

    componentDidMount() {
        this.getFlights();
    }

    async getFlights() {
        HttpGet(`${Settings.apiUrls.carbon}/user/journeys/${this.props.journeyId}`)
            .then(data => this.setState({ journey: data }))
            .catch((error) => {
                switch (error.code) {
                case 404:
                    break;
                case 408:
                    break;
                case 418:
                    break;
                default:
                    break;
                }
            });
    }

    render() {
        return <FlightDetailView onDismiss={this.props.dismiss} journey={this.state.journey} />;
    }
}

FlightDetailViewContainer.defaultProps = {
    journeyId: '',
};

FlightDetailViewContainer.propTypes = {
    journeyId: PropTypes.string,
    dismiss: PropTypes.func.isRequired,
};

export default FlightDetailViewContainer;
