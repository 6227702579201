import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Box from 'ibis-design-system/lib/components/Atoms/Box';

import { Value, Icon, Label } from './css/StatCompareBlockStyle';

import {
    Forest,
    PlaneAroundEarth,
    StopWatch,
    PlaneTakingOff,
    EmployeeRunning,
    PiggyBank,
    Train,
} from '../../Icons';

class StatCompareBlock extends Component {
    constructor(props) {
        super(props);
        this.getFormattedValue = this.getFormattedValue.bind(this);
        this.getTypeInfo = this.getTypeInfo.bind(this);
    }

    /**
     * this method return a formatted value for the value
     * @param {number} value
     * @param {string} type The value type (currently number and currency (in euro) are supported)
     */
    getFormattedValue(value, type) {
        switch (type) {
            case 'number':
                return new Intl.NumberFormat('nl-NL').format(value);
            case 'currency':
                return new Intl.NumberFormat('nl-NL', {
                    style: 'currency',
                    currency: 'EUR',
                }).format(value);
            default:
                return value;
        }
    }

    /**
     * Returns the url for the given image type
     * @param {string} type ;
     */
    getTypeInfo(type) {
        switch (type) {
            case 'Globe':
                return {
                    icon: PlaneAroundEarth,
                    alt: 'Plane flying arround the earth',
                    format: 'number',
                    prefix: '',
                    suffix: 'x',
                    label: 'arround the globe',
                };
            case 'Trees':
                return {
                    icon: Forest,
                    alt: 'Trees in forest',
                    format: 'number',
                    prefix: '',
                    suffix: '\u00B1',
                    label: 'trees for 40 years',
                };
            case 'Stopwatch':
                return {
                    icon: StopWatch,
                    alt: 'StopWatch',
                    format: 'number',
                    prefix: '\u00B1',
                    suffix: '',
                    label: 'hours of work',
                };
            case 'Train':
                return {
                    icon: Train,
                    alt: 'Train',
                    format: 'number',
                    prefix: '',
                    suffix: 'x',
                    label: 'Delft-Utrecht by train',
                };
            case 'Plane':
                return {
                    icon: PlaneTakingOff,
                    alt: 'Plane taking off',
                    format: 'number',
                    prefix: '',
                    suffix: '',
                    label: 'flights per day',
                };
            case 'Run':
                return {
                    icon: EmployeeRunning,
                    alt: 'Employee running',
                    format: 'number',
                    prefix: '\u223C',
                    suffix: '',
                    label: 'avg. flights per person',
                };
            case 'Savings':
                return {
                    icon: PiggyBank,
                    alt: 'Piggy Bank',
                    format: 'number',
                    prefix: '',
                    suffix: '%',
                    label: 'avg. flights per person',
                };
            default:
                return {
                    icon: '',
                    alt: '',
                    format: 'number',
                    prefix: '',
                    suffix: '',
                    label: '',
                };
        }
    }

    render() {
        const specs = this.getTypeInfo(this.props.type);
        return (
            <Box>
                <Icon>{specs.icon}</Icon>
                <Value>
                    {specs.prefix}
                    {this.getFormattedValue(this.props.value, specs.format)}
                    {specs.suffix}
                </Value>
                <Label>{specs.label}</Label>
            </Box>
        );
    }
}

StatCompareBlock.defaultProps = {
    height: '75px',
};

StatCompareBlock.propTypes = {
    value: PropTypes.number.isRequired,
    type: PropTypes.oneOf([
        'Globe',
        'Trees',
        'Stopwatch',
        'Plane',
        'Run',
        'Savings',
        'Train',
        'None',
    ]).isRequired,
    height: PropTypes.string,
};

export default StatCompareBlock;
