import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Shimmer, ShimmerElementType } from 'office-ui-fabric-react/lib/Shimmer';

import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import { PersonaListItemTemplate } from 'ibis-design-system/lib/components/core/ListItemTemplate';

import Settings from '../../Settings';
import { previousSearchedUsers } from '../UserCache';

const ItemBlock = styled.div`
    background-color: ${props => (props.active ? props.theme.colors.selected : props.theme.colors.base)};
    padding: 10px;
    height: 62px;
    box-sizing: border-box;
    border-color: rgba(244, 244, 244, 1);
    border-style: solid;
    border-width: 1px 0px 1px 0px;
    border-left: ${props => (props.highlight ? '2px solid' + props.theme.colors.primary : '1px')};
    display: flex;
    flex-direction: row;
`;

function PersonaItemContainer(props) {
    const { item, isSelected } = props;
    const { employeeId } = item;

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!employeeId) return;
        if (previousSearchedUsers.hasOwnProperty(employeeId)) {
            setUser(previousSearchedUsers[employeeId]);
            return;
        }
        previousSearchedUsers[employeeId] = null;
        setLoading(true);
        // prettier-ignore
        const url = `${Settings.apiUrls.singleSignOn}/users/${employeeId}/details`;
        HttpGet(url)
            .then(response => {
                previousSearchedUsers[employeeId] = { ...response };
                setUser(response);
            })
            .catch(error => {
                // Handle Error
            })
            .finally(() => setLoading(false));
    }, [employeeId]);

    if (loading)
        return (
            <ItemBlock>
                <Shimmer shimmerElements={[{ type: ShimmerElementType.circle, height: 42 }]} />
                <div style={{ marginLeft: '10px', flex: 1 }}>
                    <div style={{ marginBottom: '10px' }}>
                        <Shimmer />
                    </div>
                    <Shimmer />
                </div>
            </ItemBlock>
        );

    return (
        <PersonaListItemTemplate
            isSelected={isSelected}
            personaInformation={{
                text: user ? user.name : null,
                secondaryText: item.passportName,
                imageUrl: user ? user.photo : null,
            }}
        />
    );
}

export default PersonaItemContainer;
