import React from 'react';

import Text from 'ibis-design-system/lib/components/core/Text';

import LogRow from './LogRow';
import Flex from 'ibis-design-system/lib/components/core/Flex';
import Box from 'ibis-design-system/lib/components/core/Box';
import Button from 'ibis-design-system/lib/components/core/Button';
import LoadScreen from 'ibis-design-system/lib/components/core/LoadScreen';
import MessageBar from 'ibis-design-system/lib/components/core/MessageBar';

function FlightUploadView({ logs = [], postFile, uploading, message, reset }) {
    return (
        <>
            <Flex justifyContent="space-between">
                <Box>
                    <Text variant="label">Last 10 runs</Text>
                    {logs.map((log) => (
                        <LogRow key={log.id} {...log} />
                    ))}
                </Box>
                <Button
                    disabled={logs.length > 0 && logs[0].isCompleted}
                    onClick={reset}
                >
                    Kill running process
                </Button>
            </Flex>
            <Flex mt={5}>
                <form encType="multipart/form-data" onSubmit={postFile}>
                    <input type="file" name="travel data" accept=".xls" />
                    <button type="submit">Upload</button>
                    {uploading ? <LoadScreen /> : null}
                </form>
            </Flex>
            {message ? (
                <MessageBar type={message.type} onDismiss={message.dismiss}>
                    {message.text}
                </MessageBar>
            ) : null}
        </>
    );
}

export default FlightUploadView;
