import styled from 'styled-components';

export const Block = styled.div`
    width: 33%;
    display: inline-block;
    color: white;
    text-align: center;
    padding: 40px;
    box-sizing: border-box;
    opacity: ${props => props.opacity};
`;

export const Title = styled.h3`
    font-size: 24px;
    padding: 4px;
    margin: 0;
    font-weight: normal;
    text-align: center;
`;

export const Value = styled.h4`
    font-size: 48px;
    padding: 4px;
    margin: 0;
    font-weight: normal;
    text-align: center;
`;

export const Icon = styled.img`
    width: 75px;
    height: 75px;
    padding: 5px;
`;

export const AltValue = styled.span`
    display: block;
    font-size: 24px;
    text-align: center;
`;

export const Label = styled.label`
    font-size: 14px !important;
    text-align: center;
`;
