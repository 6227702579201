import React, { Component } from 'react';

import { HttpGet, HttpPost } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import Settings from '../../Settings';
import PassengersDetailView from '../presentationComponents/PassengersDetailView';

class PassengersContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employee: null,
            passportName: '',
        };
        this.update = this.update.bind(this);
    }
    componentDidMount() {
        if (this.props.id != null) {
            HttpGet(`${Settings.apiUrls.singleSignOn}/users/${this.props.id}/details`)
                .then(response => this.setState({ employee: response, passportName: this.props.item.passportName }))
                .catch(error => {
                    let message;
                    switch (error.code) {
                        case 404:
                            message = { type: 'error', text: `Assigned person not found` };
                            break;
                        default:
                            message = { type: 'error', text: `Unknown error occurred` };
                            break;
                    }
                    this.setState({ passportName: this.props.item.passportName, message: message });
                });
        } else {
            this.setState({ passportName: this.props.item.passportName });
        }
    }

    update(passportName, passenger) {
        HttpPost(`${Settings.apiUrls.carbon}/passengers/assign`, { passportName: passportName, employeeId: passenger.id })
            .then(response => {
                this.setState({ message: { type: 'succes', text: `${passenger.text} assigned to ${passportName}` } });
                this.props.updateItem({ passportName: passportName, employeeId: passenger.id });
            })
            .catch(error => {
                switch (error.code) {
                    case 400:
                        this.setState({ message: { type: 'error', text: `${passenger.text} is already assigned` } });
                        break;
                    case 408:
                    case 418:
                    default:
                        this.setState({ message: { type: 'error', text: `Unknown error occurred` } });
                        break;
                }
            });
    }

    render() {
        return (
            <PassengersDetailView
                key={this.state.passportName}
                employee={this.state.employee}
                name={this.state.passportName}
                update={this.update}
                message={this.state.message}
                clearMessage={() => this.setState({ message: null })}
            />
        );
    }
}

export default PassengersContainer;
