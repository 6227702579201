import styled from 'styled-components';

export const Overlay = styled.div`
    display: flex;
    position: fixed;
    left: 0;
    top: 52px;
    right: 0;
    bottom: 0;
    background: rgba(102, 153, 51, 0.75);
    transition: opacity 500ms;
    z-index: 999;
`;

export const Popup = styled.div`
    width: 960px;
    height: 640px;
    margin: auto;
    background-color: ${props => props.theme.white};
`;
