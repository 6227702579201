import styled from 'styled-components';

export const FlightBlock = styled.div`
    background-color: ${props => props.theme.white};
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    color: ${props => props.theme.text};
`;

export const Title = styled.h2`
    font-size: 32px;
    font-weight: normal;
    padding: 30px 0px 30px 30px;
    margin: 0;
`;

export const NoFlightsText = styled.h3`
    margin: 50px 0px 100px 0px;
    font-size: 48px;
    font-weight: 600;
    color: ${props => props.theme.blue};
    text-align: center;
`;

export const Footer = styled.div`
    height: 50px;
    margin-top: 70px;
    font-size: 15px;
    padding-left: 30px;
`;

export const LinkStyle = styled.a`
    color: ${props => props.theme.textHighlighted};
`;
