import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { HttpGet, HttpPost } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import Settings from '../../Settings';
import ProjectDetailView from '../presentationComponents/ProjectDetailView';

class ProjectDetailViewContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            credit: 0,
            project: {},
        };

        this.onDonate = this.onDonate.bind(this);
    }

    componentDidMount() {
        this.loadProjectInformation(this.props.id);
        this.loadUserCredit();
    }

    onDonate(donation) {
        if (donation === 0) return;
        const self = this;
        const data = {
            projectId: this.props.id,
            amount: donation,
        };
        HttpPost(`${Settings.apiUrls.carbon}/user/credit/spend`, data)
            .then(response => {
                const newState = this.state;
                newState.credit = response.credit.credit;
                newState.project = response.project;
                self.setState(newState);
            })
            .catch(error => {
                switch (error.code) {
                    case 400:
                        break;
                    case 403:
                        break;
                    case 408:
                        break;
                    case 418:
                        break;
                    default:
                        break;
                }
            });
    }

    async loadUserCredit() {
        const self = this;
        await HttpGet(`${Settings.apiUrls.carbon}/user/credit`)
            .then(response => {
                self.setState({ credit: response.credit });
            })
            .catch(error => {
                switch (error.code) {
                    case 404:
                        break;
                    case 408:
                        break;
                    case 418:
                        break;
                    default:
                        break;
                }
            });
    }

    async loadProjectInformation(id) {
        if (id == null) return;
        const self = this;
        await HttpGet(`${Settings.apiUrls.carbon}/projects/${id}`)
            .then(response => {
                self.setState({ project: response, key: self.state.key + 1 });
            })
            .catch(error => {
                switch (error.code) {
                    case 408:
                        break;
                    case 418:
                        break;
                    default:
                        break;
                }
            });
    }

    render() {
        return (
            <ProjectDetailView
                project={this.state.project}
                onDismiss={this.props.dismiss}
                onDonate={this.onDonate}
                credit={this.state.credit}
            />
        );
    }
}

ProjectDetailViewContainer.defaultProps = {
    id: null,
};

ProjectDetailViewContainer.propTypes = {
    id: PropTypes.string,
    dismiss: PropTypes.func.isRequired,
};

export default ProjectDetailViewContainer;
