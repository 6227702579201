import React, { useEffect } from 'react';

import { HttpGet, CancelToken, HttpPatch, HttpPost } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import DefaultDetailview from 'ibis-design-system/lib/components/core/DefaultDetailview';

import Settings from '../../Settings';
import ProjectDetailview from '../presentationComponents/ProjectDetailview';
import PassengersContainer from './PassengersContainer';

const cancelToken = CancelToken;
let source = null;

function DetailviewContainer(props) {
    const { selection, updateItem, items, type } = props;

    function getDetailviewConfigOptions() {
        switch (type) {
            case 'projects':
                return {
                    url: `${Settings.apiUrls.carbon}/projects/${items[selection[0]].id}`,
                    component: <ProjectDetailview item={props.items[selection[0]]} patch={patch} />,
                };
            case 'passengers':
                return {
                    url: `${Settings.apiUrls.carbon}/passengers/${items[selection[0]].id}`,
                    component: (
                        <PassengersContainer
                            key={props.items[selection[0]].passportName}
                            item={props.items[selection[0]]}
                            id={props.items[selection[0]].employeeId}
                            updateItem={value => updateItem(selection[0], value)}
                        />
                    ),
                };
            default:
                return null;
        }
    }

    useEffect(() => {
        if (selection.length !== 1) return;
        if (items[selection[0]].id == null) return;

        if (source) source.cancel();
        source = cancelToken.source();

        let url = getDetailviewConfigOptions().url;

        HttpGet(url, source.token)
            .then(response => updateItem(selection[0], response))
            .catch(error => {
                switch (error.code) {
                    case 499:
                        // Canceled
                        break;
                    default:
                        break;
                }
            });
        // React will give a warning that getDetailviewConfigOptions', 'items', and 'updateItem' are missing as dependencies. It is correct about that. But adding those as dependecies will keep triggering rerenders
    }, [selection]);

    function patch(id, patches) {
        if (id == null) {
            HttpPost(`${Settings.apiUrls.carbon}/projects`, items[selection[0]]).then(response => patch(response.id, patches));
        } else {
            HttpPatch(`${Settings.apiUrls.carbon}/projects/${id}`, patches)
                .then(response => updateItem(selection[0], response))
                .catch(error => {
                    switch (error.code) {
                        default:
                            break;
                    }
                });
        }
    }

    if (selection.length === 0) {
        return <DefaultDetailview />;
    }

    if (selection.length > 1) {
        return <DefaultDetailview title={`${selection.length} selected`}>Perform an action on the selection</DefaultDetailview>;
    }

    return getDetailviewConfigOptions().component;
}

export default DetailviewContainer;
