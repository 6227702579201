import React, { Component } from 'react';
import moment from 'moment';
import VisibilitySensor from 'ibis-helpers/lib/components/VisibilitySensor';
import PropTypes from 'prop-types';
import StatsDetailView from './StatsDetailView';
import { PanelHeader, Active, PanelHeaderElement, InActive } from './css/PanelHeaderStyle';

class StatsView extends Component {
    constructor(props) {
        super(props);
        this.state = { opacity: 0.33 };
        this.changeOpacity = this.changeOpacity.bind(this);
        this.visibilityChanged = this.visibilityChanged.bind(this);

        this.processPropertyData = this.processPropertyData.bind(this);
        this.processPropertyData();
    }

    processPropertyData() {
        // dealing with the proper data
        return {
            distance: {
                title: 'Distance',
                value: this.props.stats.distance.value,
                valueType: 'number',
                altValue: this.props.stats.distance.altValue,
                imageType: 'Globe',
            },
            emission: {
                title: 'CO\u2082 flight-footprint',
                value: this.props.stats.emission.value,
                valueType: 'number',
                altValue: this.props.stats.emission.altValue,
                imageType: 'Trees',
            },
            compensation: {
                title: 'Compensation',
                value: this.props.stats.compensation.value,
                valueType: 'currency',
                altValue: this.props.stats.compensation.altValue,
                imageType: 'Train',
            },
            flights: {
                title: 'Number of flights',
                value: this.props.stats.flights.value,
                valueType: 'number',
                altValue: this.props.stats.flights.altValue,
                imageType: 'Plane',
            },
            employee: {
                title: 'Number of employees',
                value: this.props.stats.employee.value,
                valueType: 'number',
                altValue: this.props.stats.employee.altValue,
                imageType: 'Run',
            },
            compensated: {
                title: 'Flight compensated',
                value: this.props.stats.compensated.value,
                valueType: 'number',
                altValue: this.props.stats.compensated.altValue,
                imageType: 'Savings',
            },
        };
    }

    shouldComponentUpdate(nextProps) {
        return this.state.opacity < 1 || nextProps.stats.year !== this.props.stats.year;
    }

    visibilityChanged(isVisible) {
        if (isVisible === true && this.state.opacity === 0.33) this.changeOpacity();
    }

    changeOpacity() {
        this.setState({ opacity: this.state.opacity + 2 / 3 / 100 });
        if (this.state.opacity < 1) setTimeout(this.changeOpacity, 1);
    }

    getPanelHeader(year) {
        const theme = year === parseInt(this.props.stats.year, 10) ? Active : InActive;
        return (
            <PanelHeaderElement onClick={() => this.props.onYearChange(year)} theme={theme}>
                {year}
            </PanelHeaderElement>
        );
    }

    render() {
        const data = this.processPropertyData();
        return (
            <div id="StatsView">
                <div>
                    <PanelHeader>
                        <span>Results for </span>
                        {this.getPanelHeader(parseInt(moment().format('YYYY')))}
                        &nbsp;
                        {this.getPanelHeader(parseInt(moment().subtract(1, 'year').format('YYYY')))}
                    </PanelHeader>
                </div>
                <div style={{ backgroundColor: '#666666', padding: '20px' }}>
                    <VisibilitySensor onVisibilityChanged={this.visibilityChanged} />
                    <StatsDetailView data={data.distance} opacity={this.state.opacity} />
                    <StatsDetailView data={data.emission} opacity={this.state.opacity} />
                    <StatsDetailView data={data.compensation} opacity={this.state.opacity} />
                    <StatsDetailView data={data.flights} opacity={this.state.opacity} />
                    <StatsDetailView data={data.employee} opacity={this.state.opacity} />
                    <StatsDetailView data={data.compensated} opacity={this.state.opacity} />
                </div>
            </div>
        );
    }
}

const valueShape = PropTypes.shape({
    altValue: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
});

StatsView.defaultProps = {};

StatsView.propTypes = {
    stats: PropTypes.shape({
        compensated: valueShape.isRequired,
        compensation: valueShape.isRequired,
        distance: valueShape.isRequired,
        emission: valueShape.isRequired,
        employee: valueShape.isRequired,
        flights: valueShape.isRequired,
        year: PropTypes.any.isRequired,
    }).isRequired,
    onYearChange: PropTypes.func.isRequired,
};

export default StatsView;
