import React, { useState, useEffect } from 'react';

import SDGs from 'ibis-design-system/lib/components/inputFields/SDGs/SDGs';
import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import Box from 'ibis-design-system/lib/components/core/Box';
import TextInput from 'ibis-design-system/lib/components/inputFields/TextInput';
import TextAreaInput from 'ibis-design-system/lib/components/inputFields/TextAreaInput';
import ImageUpload from 'ibis-design-system/lib/components/inputFields/ImageUpload';

import Settings from '../../../../Settings';

function General(props) {
    const { id, patch } = props;
    const [sdgs, setSDGs] = useState([]);

    useEffect(() => {
        HttpGet(`${Settings.apiUrls.globalConfig}/data/sdgs`)
            .then(response => {
                setSDGs(response);
            })
            .catch(error => {
                switch (error.code) {
                    case 408:
                        break;
                    case 418:
                        break;
                    default:
                        break;
                }
            });
    }, []);

    function handleImageUpload(image) {
        patch(id, [
            { op: 'replace', path: 'image', value: image.id },
            {
                op: 'replace',
                path: 'imageReadAccessToken',
                value: image.readAccessToken
            },
            {
                op: 'replace',
                path: 'imageWriteAccessToken',
                value: image.writeAccessToken
            }
        ]);
    }

    function handleSDGToggle(sdgId, patchObject) {
        patch(id, [patchObject]);
    }

    return (
        <>
            <Box mb={3}>
                <TextInput
                    label="Project title"
                    placeholder="Fill in the name of the project"
                    value={props.name}
                    onChanged={value =>
                        patch(id, [
                            { op: 'replace', path: 'name', value: value }
                        ])
                    }
                />
            </Box>
            <Box mb={3}>
                <TextAreaInput
                    label="Description"
                    placeholder="Let the users know what this project is about!"
                    rows={5}
                    value={props.description}
                    onChanged={value =>
                        patch(id, [
                            { op: 'replace', path: 'description', value: value }
                        ])
                    }
                />
            </Box>
            <Box mb={3}>
                <ImageUpload
                    height="500px"
                    width="500px"
                    imageUploaded={response => handleImageUpload(response)}
                    uploadUrl={`${Settings.apiUrls.fileService}/files`}
                    previewUrl={
                        props.image
                            ? `${Settings.apiUrls.fileService}/files/${props.image}`
                            : null
                    }
                />
            </Box>
            <Box mb={3}>
                <SDGs
                    availableSDGs={sdgs}
                    pickedSDGs={props.goals}
                    listener={handleSDGToggle}
                    field="goals"
                />
            </Box>
        </>
    );
}

export default General;
