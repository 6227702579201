import React, { Component } from 'react';

import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import LoadScreen from 'ibis-design-system/lib/components/core/LoadScreen';

import Settings from '../../Settings';
import DonationsDetailView from '../presentationComponents/DonationsDetailView';

class DonationsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.loadDonations = this.loadDonations.bind(this);
    }

    componentDidMount() {
        this.loadDonations(this.props.id);
    }

    loadDonations(id) {
        const self = this;
        HttpGet(`${Settings.apiUrls.carbon}/projects/${id}/compensations`)
            .then(async function handleReponse(response) {
                const users = [];
                const donations = [];
                for (let i = 0; i < response.length; i++) {
                    const donation = response[i];
                    let user = users.find(x => x.id === donation.employeeId);
                    if (!user) {
                        user = await HttpGet(`${Settings.apiUrls.singleSignOn}/users/${donation.employeeId}/details`).catch(error => {
                            switch (error.code) {
                                case 404:
                                    return { name: 'Could not find user' };
                                case 408:
                                case 418:
                                default:
                                    return { name: 'Error while retrieving user data' };
                            }
                        });
                        users.push(user);
                    }
                    donations.push({ ...donation, ...user });
                }
                donations.sort(function sortByDate(a, b) {
                    return new Date(b.spendureDate) - new Date(a.spendureDate);
                });
                self.setState({ donations: donations });
            })
            .catch(error => {
                switch (error.code) {
                    case 404:
                    case 408:
                    case 418:
                    default:
                        self.setState({ donations: [] });
                }
            });
    }

    render() {
        if (this.state.donations) {
            return <DonationsDetailView key="loaded" donations={this.state.donations} />;
        }

        return <LoadScreen />;
    }
}

export default DonationsContainer;
