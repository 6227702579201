import React, { useState, useEffect } from 'react';
import moment from 'moment';

import {
    HttpGet,
    CancelToken
} from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import ListView from './ListView';
import ItemTemplate from 'ibis-design-system/lib/components/core/ListItemTemplate';
import Text from 'ibis-design-system/lib/components/core/Text';

import Settings from '../../Settings';
import PersonaItemContainer from './PersonaItemContainer';

const cancelToken = CancelToken;
let source = null;

function CollectionContainer(props) {
    const { url, setItems } = props;
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (!url) return;
        if (source) source.cancel();
        source = cancelToken.source();

        // prettier-ignore
        HttpGet(`${Settings.apiUrls.carbon}/${url}`, source.token)
            .then((response) => {
                setItems(response);
            })
            .catch((error) => {
                const errorObject = {
                    type: 'error',
                    onDismiss: () => setMessage(null),
                };
                switch (error.code) {
                case 499:
                    // Canceled request
                    return () => (false);
                case 403:
                    errorObject.text = 'Forbidden';
                    break;
                case 404:
                    errorObject.text = 'Endpoint does not exist';
                    break;
                default:
                    errorObject.text = 'Unknow Error occurred';
                    break;
                }

                setItems([]);
                setMessage(errorObject);
            });
        // React will give a warning that setItems is missing as a dependency. It is correct about that. but adding that as a dependecy will keep triggering rerenders
    }, [url]);

    function calculatePercentage(
        compensationValueSpend,
        compensationValueGoal
    ) {
        const percentage = Math.round(
            (compensationValueSpend / compensationValueGoal) * 100
        );
        if (isNaN(percentage)) return 0;
        return percentage;
    }

    function itemRenderer(item, index, isSelected) {
        if (props.type === 'passengers') {
            return <PersonaItemContainer item={item} isSelected={isSelected} />;
        }

        const end = item.end
            ? moment(item.end).format('MMM D, YYYY')
            : 'No end date set';
        const compensationValueSpend = new Intl.NumberFormat('nl-NL').format(
            item.compensationValueSpend
        );
        const compensationValueGoal = new Intl.NumberFormat('nl-NL').format(
            item.compensationValueGoal
        );

        return (
            <ItemTemplate
                isSelected={isSelected}
                hasNotifications={item.hasNotification}
            >
                <ItemTemplate.Title>{item.name}</ItemTemplate.Title>
                <ItemTemplate.SubText>
                    <Text cannotSelect>
                        {`EUR ${compensationValueSpend} / ${compensationValueGoal} (${calculatePercentage(
                            item.compensationValueSpend,
                            item.compensationValueGoal
                        )}%)`}
                    </Text>
                    <Text cannotSelect>{`${moment(item.start).format(
                        'MMM D, YYYY'
                    )} - ${end}`}</Text>
                </ItemTemplate.SubText>
            </ItemTemplate>
        );
    }

    return (
        <ListView
            flex="1 1 auto"
            message={message}
            onSelectionChanged={selectedItems =>
                props.onSetSelectedItems(selectedItems)
            }
            onRenderListItem={itemRenderer}
            itemHeight={props.type === 'passengers' ? 62 : 82}
            items={props.items}
            selectedItems={props.selectedItems}
        />
    );
}

export default CollectionContainer;
