import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from 'ibis-design-system/lib/components/core/Layout';
import ThemeProvider from 'ibis-design-system/lib/Providers/ThemeProvider';

import HeaderContainer from './HeaderContainer';
import ContentContainer from './ContentContainer';

function ContentWrapper(props) {
    const [panelContent, setPanelContent] = useState(null);
    document.title = 'Carbon Awareness Initiative • IBIS';
    return (
        <ThemeProvider>
            <Layout>
                <Layout.Header>
                    <HeaderContainer
                        {...props}
                        setPanelContent={setPanelContent}
                    />
                </Layout.Header>
                <Layout.Detailview>
                    <ContentContainer {...props} />
                </Layout.Detailview>
                {panelContent ? (
                    <Layout.PanelContent>{panelContent}</Layout.PanelContent>
                ) : null}
            </Layout>
        </ThemeProvider>
    );
}

ContentWrapper.defaultProps = {
    division: '',
    user: {},
};

ContentWrapper.propTypes = {
    division: PropTypes.string,
    user: PropTypes.object,
};

export default ContentWrapper;
