import React, { useState } from 'react';

import Detailview from 'ibis-design-system/lib/components/core/Detailview';
import Box from 'ibis-design-system/lib/components/core/Box';

import ProjectOverview from './Project/ProjectOverview';
import ProjectEditview from './Project/ProjectEditview';
import DonationsContainer from '../containerComponents/DonationsContainer';

function ProjectDetailview(props) {
    const { currentTab } = props;
    const [message, setMessage] = useState(null);
    const messageHelper = {
        onMessage: (message) => setMessage(message),
        onDismiss: () => setMessage(null),
    };

    return (
        <Detailview
            title={{ text: props.item.name }}
            message={message}
            selectedKey={currentTab}
            onTabClick={(item) => {}}
            tabs={[
                {
                    key: 'overview',
                    name: 'Overview',
                    component: (
                        <ProjectOverview {...props.item} {...messageHelper} />
                    ),
                },
                {
                    key: 'project',
                    name: 'Project',
                    component: (
                        <Box width="100%" px={3}>
                            <ProjectEditview
                                {...props.item}
                                {...messageHelper}
                                patch={props.patch}
                            />
                        </Box>
                    ),
                },
                {
                    key: 'donations',
                    name: 'Donations',
                    component: <DonationsContainer id={props.item.id} />,
                },
                // {
                //     key: 'messages',
                //     name: 'Messages',
                //     component: (
                //         <UserContext.Consumer>
                //             {user => (
                //                 <Messages
                //                     id={props.item.id}
                //                     userId={user.id}
                //                     onSend={(content, html) => alert(content, html)}
                //                     messages={[]}
                //                 />
                //             )}
                //         </UserContext.Consumer>
                //     ),
                // },
                // {
                //     key: 'documents',
                //     name: 'Documents',
                //     component: <Documents documents={[]} />,
                // },
            ]}
        />
    );
}

export default ProjectDetailview;
