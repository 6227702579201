import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import { IconButton } from 'office-ui-fabric-react/lib-commonjs/Button';

import FlightMap from './FlightMap';
import StatCompareBlock from './StatCompareBlock';

import {
    DetailViewBox,
    BackArrow,
    CloseButton,
    Header,
    Route,
    SubHeader,
    FlightMapBox,
    InfoBlock,
    Label,
    Value,
    ContentBlock,
} from './css/FlightDetailViewStyle';

class FlightDetailView extends Component {
    formatRoute(route) {
        return route.split('/').join(' - ');
    }

    render() {
        const flights = [];
        if (this.props.journey.flights !== undefined && this.props.journey.flights.length !== 0) {
            this.props.journey.flights.forEach((flight) => {
                flights.push({ lat: flight.originLocation.latitude, lng: flight.originLocation.longitude });
            });
            const endpoint = this.props.journey.flights[this.props.journey.flights.length - 1];
            flights.push({ lat: endpoint.destinationLocation.latitude, lng: endpoint.destinationLocation.longitude });
        }

        const distance = new Intl.NumberFormat('nl-NL').format(this.props.journey.distance);
        const emission = new Intl.NumberFormat('nl-NL').format(this.props.journey.emission);
        const options = { style: 'currency', currency: 'EUR' };
        const compensation = Intl.NumberFormat('nl-NL', options).format(this.props.journey.compensationValue);

        return (
            <DetailViewBox>
                <Header>
                    <BackArrow>
                        <IconButton
                            style={{ color: 'inherit' }}
                            iconProps={{ iconName: 'ChevronLeftSmall' }}
                            onClick={this.props.onDismiss}
                        />
                    </BackArrow>
                    <Route>{this.formatRoute(this.props.journey.route)}</Route>
                    <CloseButton>
                        <IconButton style={{ color: 'inherit' }} iconProps={{ iconName: 'ChromeClose' }} onClick={this.props.onDismiss} />
                    </CloseButton>
                </Header>
                <SubHeader>{Moment(this.props.journey.departureDate).format('YYYY-MM-DD')}</SubHeader>
                <FlightMapBox>
                    <FlightMap flights={flights} />
                </FlightMapBox>
                <ContentBlock>
                    <InfoBlock>
                        <Label>Distance (km)</Label>
                        <Value>{distance}</Value>
                        <StatCompareBlock height="40px" type="Globe" value={this.props.journey.globeSpins} />
                    </InfoBlock>
                    <InfoBlock>
                        <Label>
                            Footpint (t CO
                            <sub>2</sub>)
                        </Label>
                        <Value>{emission}</Value>
                        <StatCompareBlock height="37px" type="Trees" value={this.props.journey.trees} />
                    </InfoBlock>
                    <InfoBlock>
                        <Label>Compensation (EUR)</Label>
                        <Value>{compensation}</Value>
                        <StatCompareBlock height="40px" type="Train" value={this.props.journey.publicTransportationCost} />
                    </InfoBlock>
                </ContentBlock>
            </DetailViewBox>
        );
    }
}

const destinationLocationShape = PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
});

const journeyShape = PropTypes.shape({
    compensationValue: PropTypes.number.isRequired,
    distance: PropTypes.number.isRequired,
    emission: PropTypes.number.isRequired,
    flights: PropTypes.arrayOf(PropTypes.shape({
        destinationCode: PropTypes.string.isRequired,
        destinationLocation: destinationLocationShape.isRequired,
        originCode: PropTypes.string.isRequired,
        originLocation: destinationLocationShape.isRequired,
    }).isRequired).isRequired,
    globeSpins: PropTypes.number.isRequired,
    publicTransportationCost: PropTypes.number.isRequired,
    route: PropTypes.string.isRequired,
    trees: PropTypes.number.isRequired,
});

FlightDetailView.propTypes = {
    journey: journeyShape.isRequired,
    onDismiss: PropTypes.func.isRequired,
};

export default FlightDetailView;
