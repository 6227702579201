import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Block, Title, Value } from './css/StatsDetailViewStyle';
import StatCompareBlock from './StatCompareBlock';

class StatsDetailView extends Component {
    constructor(props) {
        super(props);
        this.getFormattedValue = this.getFormattedValue.bind(this);
    }

    /**
     * this method return a formatted value for the value
     * @param {number} value
     * @param {string} type The value type (currently number and currency (in euro) are supported)
     */
    getFormattedValue(value, type) {
        switch (type) {
        case 'number':
            return new Intl.NumberFormat('nl-NL').format(value);
        case 'currency':
            return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(value);
        default:
            return value;
        }
    }

    render() {
        return (
            <Block opacity={this.props.opacity}>
                <Title>{this.props.data.title}</Title>
                <Value>{this.getFormattedValue(this.props.data.value, this.props.data.valueType)}</Value>
                <StatCompareBlock value={this.props.data.altValue} type={this.props.data.imageType} />
            </Block>
        );
    }
}

StatsDetailView.defaultProps = {
    data: {
        altValue: 0,
        imageType: 'None',
        title: '',
        value: 0,
        valueType: 'number',
    },
    opacity: 0.333,
};

const dataType = PropTypes.shape({
    altValue: PropTypes.number.isRequired,
    imageType: PropTypes.oneOf(['Globe', 'Trees', 'Stopwatch', 'Plane', 'Run', 'Savings', 'Train', 'None']).isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    valueType: PropTypes.oneOf(['number', 'currency']).isRequired,
}).isRequired;

StatsDetailView.propTypes = {
    data: dataType,
    opacity: PropTypes.number,
};

export default StatsDetailView;
