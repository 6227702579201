import React from 'react';

import { theme } from 'ibis-helpers/lib/Theme';
import { ThemeProvider } from 'styled-components';

import Content from './Content';

const ContentContainer = (props) => {
    let customTheme = {
        appBackground: '#669933',
        error: '#BF0000',
        overviewBars: '#FFFFFF',
        overviewBarsBackground: 'rgba(255, 255, 255, 0.33)',
        overviewAlt: 'rgba(255, 255, 255, 0.15)',
    };
    customTheme = Object.assign(theme, customTheme);

    return (
        <ThemeProvider theme={customTheme}>
            <Content {...props} />
        </ThemeProvider>
    );
};

export default ContentContainer;
