import styled from 'styled-components';

export const Label = styled.span`
    font-size: 24px;
    font-weight: 600;
`;

export const SmallLabel = styled.span`
    font-weight: 600;
    font-size: 18px;
`;

export const BigLabel = styled.span`
    font-weight: 600;
    font-size: 48px;
`;

export const OverviewBlock = styled.div`
    display: flex;
    color: ${props => props.theme.white};
`;

export const ProgressBarBox = styled.div`
    margin-top: 30px;
`;

export const BarLabel = styled.span`
    font-size: 18px;
`;

export const FootNote = styled.div`
    color: ${props => props.theme.white};
    opacity: 0.5;
    font-size: 12px;
    margin-top: 10px;
`;

export const StatsHeader = styled.p`
    font-size: 24px;
    padding-bottom: 30px;
    font-weight: normal;
    margin: 0;
`;

export const StatsBlock = styled.div`
    flex: 4.5;
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    padding-right: 30px;
`;

export const LogoBlock = styled.div`
    flex: 4;
    text-align: right;
`;

export const Title = styled.h1`
    font-size: 52px;
    line-height: 1;
    font-weight: 600;
    margin: 0;
    word-spacing: 100000px;
`;

export const Logo = styled.div`
    padding-left: 50px;
    width: 100%;
    height: 300px;
`;

export const EarthImg = styled.img`
    margin-top: 30px;
    animation: spin 60s linear infinite;
`;

export const CompensateBlock = styled.div`
    flex: 5;
    padding-top: 60px;
`;

export const Paragraph = styled.p`
    font-size: 24px;
    padding-left: 80px;
    margin: 0;
`;

export const SmallEmissionValue = styled.span`
    font-size: 41px;
    font-weight: 600;
    text-align: right;
`;

export const EmissionValue = styled.span`
    font-size: 48px;
    font-weight: 600;
    text-align: right;
`;

export const CreditValue = styled.span`
    font-size: 24px;
    text-align: right;
`;

export const CurrentBlock = styled.div`
    padding-top: 50px;
    padding-left: 90px;
    padding-right: 30px;
`;

export const CurrentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-direction: ${props => (props.column ? 'column' : 'row')};
`;

export const CurrentWrapperHeader = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-direction: ${props => (props.column ? 'column' : 'row')};
`;

export const CurrentValue = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -15px;
`;

export const MyFootPrintBlock = styled.div`
    background-color: ${props => (props.frequentFlyer ? props.theme.error : props.theme.overviewAlt)};
    margin-left: 60px;
    margin-top: 50px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        left: calc(50% - 25px);
        top: 0px;
        border-top: 25px solid ${props => props.theme.appBackground};
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
    }
    &:after {
        content: '';
        position: absolute;
        left: calc(50% - 25px);
        top: 100%;
        border-top: 25px solid ${props => (props.frequentFlyer ? props.theme.error : props.theme.overviewAlt)};
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
    }
`;

export const MyFootPrint = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
`;

export const CreditLabel = styled.span`
    font-size: 24px;
    font-weight: normal;
    margin-top: 5px;
`;

export const MyFootPrintLeft = styled.span`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
`;

export const MyFootPrintRight = styled.span`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
    text-align: right;
`;

export const FootPrintWrapper = styled.div`
    display: flex;
    position: relative;
`;

export const FootPrintLabel = styled.span`
    font-size: 24px;
    font-weight: 600;
    position: absolute;
    bottom: 0;
`;

export const FootPrintValue = styled.span`
    font-size: 48px;
    margin-left: auto;
    font-weight: 600;
`;

export const BalanceWrapper = styled.div`
    display: flex;
`;

export const BalanceValue = styled.span`
    font-size: 24px;
    font-weight: 600;
    margin-left: auto;
`;

export const StickyHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 1200px;
    position: fixed;
    margin-top: -120px;
    height: 100px;
    background-color: ${props => props.theme.blue};
    z-index: 1;
    visibility: ${props => (props.active ? 'visible' : 'hidden')};
`;

export const StickyFootPrintBlock = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2.5;
    padding-top: 5px;
    padding-right: 30px;
`;

export const StickyFootPrintWrapper = styled.div`
    display: flex;
    position: relative;
`;
export const StickyFootPrintLabel = styled.span`
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    bottom: 0;
`;

export const StickyFootPrintValue = styled.span`
    font-size: 41px;
    font-weight: 600;
    margin-left: auto;
`;

export const StickyBalanceWrapper = styled.div`
    display: flex;
`;

export const StickyBalanceLabel = styled.span`
    font-size: 18px;
    font-weight: 600;
`;

export const StickyBalanceValue = styled.span`
    font-size: 18px;
    font-weight: 600;
    margin-left: auto;
`;

export const StickyTitleBlock = styled.div`
    flex: 3;
    display: flex;
    text-align: right;
`;

export const StickyTitle = styled.h1`
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
    word-spacing: 100000px;
    flex: 1;
`;
export const StickyLogo = styled.div`
    width: 100px;
    height: 100px;
`;

export const StickyPlane = styled.div`
    position: relative;
    z-index: 1;
`;

export const StickyEarth = styled.div`
    position: relative;
    z-index: -1;
`;
