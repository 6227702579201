import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Overlay, Popup } from './css/DialogStyle';

class Dialog extends Component {
    render() {
        return (
            <Overlay onClick={this.props.dismiss}>
                <Popup onClick={event => event.stopPropagation()}>{this.props.children}</Popup>
            </Overlay>
        );
    }
}

Dialog.propTypes = {
    dismiss: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Dialog;
