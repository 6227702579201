import React from 'react';
import ReactDOM from 'react-dom';
import 'es6-shim';
import 'office-ui-fabric-react/dist/css/fabric.min.css';
import 'react-circular-progressbar/dist/styles.css';
import { initializeIcons } from 'office-ui-fabric-react/lib-commonjs/Icons';

import LoginProvider from 'ibis-design-system/lib/Providers/LoginProvider';
import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import IEPage, {
    isUsingInternetExplorer,
} from 'ibis-design-system/lib/components/core/IEPage';

import './index.css';
import App from './App';
import Settings, { SetSettings } from './Settings';
import * as serviceWorker from './serviceWorker';

if (isUsingInternetExplorer()) {
    ReactDOM.render(<IEPage />, document.getElementById('root'));
} else {
    initializeIcons();

    let settingsPath = '/appSettings.json';
    if (process.env.NODE_ENV === 'development')
        settingsPath = '/appSettings.dev.json';

    HttpGet(settingsPath).then((response) => {
        SetSettings(response);
        ReactDOM.render(
            <LoginProvider
                api={Settings.apiUrls.carbon}
                ssoApi={Settings.apiUrls.singleSignOn}
                sso={Settings.uiUrls.singleSignOn}
                logApi={Settings.apiUrls.logService}
                returnUrl={window.location.href}
            >
                <App />
            </LoginProvider>,
            document.getElementById('root')
        );
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
