import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';

import { getMenuItemById } from 'ibis-helpers/lib/helpers/MenuHelper';

import CollectionHeader from 'ibis-design-system/lib/components/core/CollectionHeader';
import Layout from 'ibis-design-system/lib/components/core/Layout';
import Flex from 'ibis-design-system/lib/components/core/Flex';
import Header from 'ibis-design-system/lib/components/core/Header';
import Toolbar from 'ibis-design-system/lib/components/Molecules/Toolbar';
import Menu from 'ibis-design-system/lib/components/core/Menu';
import Button from 'ibis-design-system/lib/components/core/Button';
import { HttpPatch } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import { useUserState } from 'ibis-design-system/lib/Providers/LoginProvider';

import Settings from '../../Settings';
import CollectionContainer from '../containerComponents/CollectionContainer';
import DetailviewContainer from '../containerComponents/DetailviewContainer';
import FlightUploadContainer from '../containerComponents/FlightUploadContainer';

const menu = [
    {
        id: 'projects',
        name: 'Projects',
        expanded: true,
        items: [
            {
                id: 'draft-projects',
                name: 'Draft',
                url: 'projects?status=draft',
                type: 'projects',
            },
            {
                id: 'published-projects',
                name: 'Published',
                url: 'projects?status=published',
                type: 'projects',
            },
            {
                id: 'archived-projects',
                name: 'Archived',
                url: 'projects?status=archived',
                type: 'projects',
            },
        ],
    },
    {
        id: 'passengers',
        name: 'Passengers',
        url: 'passengers',
        type: 'passengers',
    },
];

function ControlPanel(props) {
    const [selectedMenuItem, setSelectedMenuItem] = useState(
        getMenuItemById(menu, 'published-projects')
    );
    const user = useUserState();
    const [collectionUrl, setCollectionUrl] = useState(selectedMenuItem.url);
    const [items, setItems] = useState(null);
    const [selection, setSelection] = useState([]);
    const [showUploadPanel, setShowUploadPanel] = useState(false);
    const [panelContent, setPanelContent] = useState(null);

    useEffect(() => {
        setCollectionUrl(selectedMenuItem.url);
    }, [selectedMenuItem]);

    useEffect(() => {
        if (selection.length === 0) {
            document.title =
                selectedMenuItem.name + ' • Carbon Awareness Initiative • IBIS';
        } else {
            if (items) {
                document.title =
                    items[selection]?.name +
                    ' • ' +
                    selectedMenuItem.name +
                    ' • Carbon Awareness Initiative • IBIS';
            }
        }
    }, [items, selectedMenuItem, selection]);

    function addItems(newItems) {
        if (items) {
            setItems(items.concat(newItems));
        } else {
            setItems(newItems);
        }
    }

    function updateItem(index, value) {
        const itemsCopy = [...items];
        itemsCopy[index] = value;
        setItems(itemsCopy);
    }

    if (!selectedMenuItem) return null;

    const toolbarItems = [
        {
            key: 'publish project',
            text: 'Publish project',
            icon: 'Upload',
            disabled:
                selection.length !== 1 ||
                items[selection[0]].status === 'Published' ||
                items[selection[0]].status === 'Archived' ||
                items[selection[0]].id === null ||
                selectedMenuItem.type !== 'projects',

            onClick: () =>
                HttpPatch(
                    `${Settings.apiUrls.carbon}/projects/${
                        items[selection[0]].id
                    }`,
                    [
                        {
                            op: 'replace',
                            path: 'status',
                            value: 1,
                        },
                    ]
                ),
        },
        {
            key: 'archive project',
            text: 'Archive project',
            icon: 'Archive',
            disabled:
                selection.length !== 1 ||
                items[selection[0]].status === 'Archived' ||
                selectedMenuItem.type !== 'projects',
            onClick: () =>
                HttpPatch(
                    `${Settings.apiUrls.carbon}/projects/${
                        items[selection[0]].id
                    }`,
                    [
                        {
                            op: 'replace',
                            path: 'status',
                            value: 2,
                        },
                    ]
                ),
        },
        {
            key: 'upload flights',
            text: 'Upload flights',
            icon: 'Airplane',
            onClick: () => setShowUploadPanel(true),
        },
        {
            key: 'carbon awareness initiative',
            text: 'Carbon Awareness Initiative',
            icon: 'Globe',
            onClick: () => (window.location.href = '/dashboard'),
        },
    ];

    return (
        <>
            <Panel
                isOpen={showUploadPanel}
                isLightDismiss={true}
                onDismiss={() => setShowUploadPanel(false)}
                type={PanelType.medium}
                headerText="Upload Flights"
            >
                <FlightUploadContainer />
            </Panel>
            <Layout>
                <Layout.Header>
                    <Header
                        user={user}
                        singleSignOutUrl={
                            Settings.uiUrls.singleSignOn + '/signout'
                        }
                        applicationName={props.title}
                        applications={props.applications}
                        portalUrl={Settings.uiUrls.portal}
                        notificationsApiUrl={Settings.apiUrls.portal}
                        onToolsButtonClick={setPanelContent}
                    />
                </Layout.Header>
                <Layout.PrimaryToolbarButton>
                    <Button
                        icon="Add"
                        onClick={() => {
                            const defaultItem = {
                                id: null,
                                name: 'New Project',
                                start: moment().toISOString(),
                                end: moment().add(1, 'years').toISOString(),
                                goals: [],
                                locations: [],
                                compensationValueGoal: 0,
                                compensationValueProgress: 0,
                                compensationValueSpend: 0,
                                description: '',
                                status: 0,
                                image: null,
                            };
                            setSelectedMenuItem(
                                getMenuItemById(menu, 'draft-projects')
                            );
                            setItems([defaultItem]);
                            setSelection([0]);
                        }}
                        variant="primary"
                    >
                        Add Project
                    </Button>
                </Layout.PrimaryToolbarButton>
                <Layout.Toolbar>
                    <Toolbar items={toolbarItems} />
                </Layout.Toolbar>
                <Layout.Menu>
                    <Menu
                        id="caiMenu"
                        items={menu}
                        selected={selectedMenuItem.id}
                        onLinkClick={(item) => {
                            setSelectedMenuItem(item);
                            setItems(null);
                            setSelection([]);
                        }}
                    />
                </Layout.Menu>
                <Layout.Collection>
                    <CollectionHeader title={selectedMenuItem.name}>
                        <Flex height="100%">
                            <CollectionContainer
                                url={collectionUrl}
                                type={selectedMenuItem.type}
                                setItems={(items) => addItems(items)}
                                items={items}
                                onSetSelectedItems={(indexes) =>
                                    setSelection(indexes)
                                }
                                selectedItems={selection}
                            />
                        </Flex>
                    </CollectionHeader>
                </Layout.Collection>
                <Layout.Detailview>
                    <DetailviewContainer
                        type={selectedMenuItem.type}
                        items={items}
                        selection={selection}
                        updateItem={updateItem}
                    />
                </Layout.Detailview>
                {panelContent ? (
                    <Layout.PanelContent>{panelContent}</Layout.PanelContent>
                ) : null}
            </Layout>
        </>
    );
}

export default ControlPanel;
